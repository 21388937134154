<template>
	<div @updatedQueue="updateQueue" class="dash">
		<perfect-scrollbar>
			<div class="wrapper wrapper--dash">
				<h2 class="title">{{$t('products.upload_queue')}}</h2>
				<div class="upload-queue">
					<div class="upload-queue__control">
						<div class="col">
							<Multiselect
								v-model="groupCategory"
								:searchable="true"
								:options="uploadTypeSelectDatasource"
								:placeholder="$t('products.select_category')"
							>
							</Multiselect>
						</div>
						<div class="col">
							<Multiselect
								v-model="groupBrand"
								@change="groupTags = []"
								:options="brandSelectOptions"
								:searchable="true"
								:placeholder="$t('products.select_brand')"
							>
							</Multiselect>
						</div>
						<div class="col">
							<Multiselect
								v-model="groupTags"
								mode="tags"
								:searchable="true"
								:options="tagSelectDatasource"
								:placeholder="$t('tags.add_tags')"
							>
								<template v-slot:noresults>
									<span class="multiselect-noresult">{{$t('tag_select.no_results')}}</span>
								</template>
							</Multiselect>
						</div>
					</div>
					<div class="table table--list table--upload" v-if="uploadQueue.length > 0">
						<div class="table__row"
							v-for="(item, index) in fileQueue"
							:key="index"
						>
							<div class="table__col table__col--img">
								<img :src="previewQueue[index]" :alt="uploadQueue[index].name" width="62" height="42">
							</div>
							<div class="table__col table__col--title">
								<strong>{{uploadQueue[index].name}}</strong>
							</div>
							<div class="table__col">
								<div class="form__item form__item--queue">
									<input v-model="item.name" :placeholder="$t('products.filename')">
								</div>
							</div>
							<div class="table__col">
								<div v-if="isProductUpload" class="form__item form__item--queue">
									<input v-model="item.sap_id" placeholder="SAP ID">
								</div>
								<div v-else class="form__item form__item--multiselect">
									<Multiselect
										v-model="item.ids"
										mode="tags"
										:searchable="true"
										:valueProp="'id'"
										:label="'sap_id'"
										:trackBy="'sap_id'"
										:options="productSelectDatasource"
										:placeholder="$t('products.add_sap_id')"
									>
										<template v-slot:noresults>
											<span class="multiselect-noresult">{{$t('products.no_products_to_assign')}}</span>
										</template>
									</Multiselect>
								</div>
							</div>
							<div class="table__col">
								<div v-if="isProductUpload" class="form__item form__item--queue">
									<input v-model="item.ean" placeholder="EAN">
								</div>
								<div v-else class="form__item form__item--multiselect">
									<Multiselect
										v-model="item.ids"
										mode="tags"
										:searchable="true"
										:valueProp="'id'"
										:label="'ean'"
										:trackBy="'ean'"
										:options="productSelectDatasource"
										:placeholder="$t('products.add_ean')"
									>
										<template v-slot:noresults>
											<span class="multiselect-noresult">{{$t('products.no_products_to_assign')}}</span>
										</template>
									</Multiselect>
								</div>
							</div>
							<div class="table__col table__col--right">
								<div class="">
									<button @click="removeFromQueue(index)"><i class="icon icon--trash-gray"></i></button>
								</div>
							</div>
						</div>
					</div>
					<div class="row" v-else>
						<UploadDropTarget class="drop-container--queue">
							<div class="button-upload-round" :title="$t('products.drag_files')"></div>
							<strong>{{$t('products.drag_files')}}</strong>
						</UploadDropTarget>
					</div>
				</div>
				<div class="flex flex-column flex-row-md justify-content-md--space-between m-2-5-0">
					<div class="w-48-md" v-if="uploadQueue.length > 0">
						<UploadDropTarget class="drop-container--queue drop-container--queue-next">
							<div class="button-upload-round" :title="$t('products.drag_more_files')"></div>
							<strong>{{$t('products.drag_more_files')}}</strong>
						</UploadDropTarget>
					</div>
					<div class="w-48-md m-1-0-0-0 m-md-0 flex justify-content-md--end">
						<div v-if="uploadQueueLength > 0 && !uploading">
							<button class="btn btn--red btn--radius-5 btn--p-1-2" @click="upload">
								<span class="btn__text btn__text--white">{{$t('products.upload_files')}}</span>
							</button>
						</div>
						<div v-else-if="uploading">
							<button class="btn btn--red btn--radius-5 btn--p-1-2" disabled>
								<span class="btn__text btn__text--spinner btn__text--white">{{$t('products.uploading_files')}} <span class="spinning-loader spinning-loader--white"></span></span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</perfect-scrollbar>
		<teleport to="#modals">
			<SuccessPopup v-if="successPopup" :open="successPopup" @close-modal="successPopup = false"></SuccessPopup>
		</teleport>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import { $t } from '@/config'
import {FileUpload, FileUploadQueue} from "@/types";
import {UploadType} from "@/enums";

import {removeFromQueue, state as uploadState, uploadResetQueue} from "@/store/upload";
import {state as brandState} from "@/store/brands";
import {state as tagState} from "@/store/tags";
import {state as productState} from "@/store/products";

import BackendAPI from "@/api/BackendAPI";
import UploadDropTarget from "@/components/Upload/UploadDropTarget.vue";
import {setErrorMessage} from "@/store/app";
import SuccessPopup from "@/components/Popup/SuccessPopup.vue";


export default defineComponent({
	name: 'ProductsUpload',
	components: {SuccessPopup, UploadDropTarget },
	emits: ['updatedQueue'],

	data(): {
		fileQueue: FileUpload[],
		uploading: boolean,
		successPopup: boolean,
		groupBrand: number,
		groupTags: [],
		groupCategory: UploadType,
		previews: [],
	} {
		return {
			fileQueue: [],
			uploading: false,
			successPopup: false,
			groupBrand: brandState.currentId,
			groupTags: [],
			groupCategory: UploadType.PRODUCT,
			previews: [],
		}
	},

	mounted() {
		this.updateQueueLength(this.uploadQueueLength)
	},

	computed: {
		uploadQueueLength() { return uploadState.uploadData.length },
		uploadQueue() { return uploadState.uploadData },
		previewQueue() { return uploadState.previewData },
		brandSelectOptions() { return brandState.data },
		tagSelectDatasource() {
			// @ts-ignore
			const groupBrand = this.groupBrand || brandState.currentId
			return tagState.data.filter(
				(tag) => tag.global === true || tag.brands && tag.brands.includes(groupBrand)
			)
		},
		productSelectDatasource() {
			//this.fileQueue.forEach((item) => {item.})
			// @ts-ignore
			const groupBrand = this.groupBrand || brandState.currentId

			return productState.data.filter(
				(product) => product.brand_id === groupBrand
			)
		},
		uploadTypeSelectDatasource() {
			return Object.keys(UploadType).map((value) => { return {value: UploadType[value], 'label': $t('type.' + UploadType[value])}})
		},
		isProductUpload(): boolean {
			return this.groupCategory === UploadType.PRODUCT ||
				this.groupCategory === UploadType.PRODUCT_SK ||
				this.groupCategory === UploadType.PRODUCT_HU
		}
	},

	watch: {
		uploadQueueLength(newValue) { this.updateQueueLength(newValue) },
	},

	methods: {
		updateQueueLength(length) {
			while (this.fileQueue.length < length) {
				this.fileQueue.push({
					name: "",
					description: "",
					tags: [],
					ean: '',
					sap_id: '',
					ids: [],
				});
			}
		},

		removeFromQueue(idx) {
			removeFromQueue(idx)
			this.fileQueue = this.fileQueue.filter((value, index, arr) => index !== idx)
		},

		async upload() {
			this.uploading = true
			let data: FileUploadQueue = {
				type: this.groupCategory,
				file: [],
				brandId: this.groupBrand,
				tags: this.groupTags,
			}
			let formData = new FormData()
			this.uploadQueue.forEach((f,x) => {
				formData.append(`file[${x}]`, f)
				data.file[x] = this.fileQueue[x]
			});
			formData.append('data', JSON.stringify(data))

			try {
				const uploadResult = await BackendAPI.post('upload/upload', formData, {
					'headers': {
						'Accept': 'application/json',
						'Content-Type': 'multipart/form-data',
					},
				})

				this.uploading = false

				if (uploadResult.status !== 200) {
					setErrorMessage('Upload failed')
					throw new Error('Upload failed');
				}

				uploadResetQueue();
				this.fileQueue = [];

				this.successPopup = true

				//pushProductData(uploadResult.data.data)

			} catch (e) {
				console.log(e)
			}
		}

	}
})
</script>
