
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'CheckboxInput',
	emits: ['input', 'update:modelValue'],

	computed: {
		localValue: {
			get(): Boolean { return this.modelValue; },
			set(value: Boolean) { return this.$emit('update:modelValue', value); }
		}
	},

	props: {
		idCheckbox: String,
		checkboxLabel: String,
		modelValue: {
			type: Boolean,
			required: true,
		}
	},
})
