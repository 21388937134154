import {UserData} from "@/types";

interface State {
	data: UserData[],
	dirty: boolean,
}

const state: State = {
	data: [],
	dirty: true,
}

export default state
