import { vueEnv } from '@/config'
import {isAdmin, isAuthenticated, isExternal} from "@/store/auth";
import {state} from "@/store/auth";

//export const loadPage = name => import(`@/pages/${name}Page`)

export const redirectToLoginIfNotAuthenticated = (to, from, next) => {
	if (! isAuthenticated.value) {
		next('/login')
	}

	if(!isAdmin && isExternal.value && !to.meta.hasOwnProperty('allowExternal')) {
		next('/401')
	}

	next()
}

export const errorIfNotAdmin = (to, from, next) => {
	alert(isAdmin.value)
	if (isAdmin.value) {
		next()
		return
	}
	next('/401')
}

export const redirectToHomeIfAuthenticated = (to, from, next) => {
	if (isAuthenticated.value) {
		next('/')
		return
	}
	next()
}

export const isEnvTest = (to, from, next) => {
	if (vueEnv === 'TEST' || vueEnv === 'DEV') {
		next()
		return
	}
	next('/')
}
