<template>
    <div class="search-bar flex align-items--center">
		<div class="form__item form__item--search flex align-items--center">
			<div class="dropdown" v-click-away.stop="hideDropDown">
				<a @click.prevent="showDropDown">
					<i :class="'icon icon--category icon' + activeItem.icon_class" v-html="activeItem.icon"></i>
					<span>{{ $t(activeItem.label) }}</span>
					<i class="icon icon--arrow icon--arrow-down"></i>
				</a>
				<transition name="fade">
					<div class="dropdown__menu" v-if="show">
						<ul>
							<li :key="searchbaritem.id" v-for="searchbaritem in searchbarItems">
								<SearchBarItem @click.prevent="gotoRoute(searchbaritem.route)" :searchbaritem="searchbaritem" />
							</li>
						</ul>
					</div>
				</transition>
			</div>
			<Multiselect
				v-if="areTagsEnabled"
				v-model="tags"
				mode="tags"
				:searchable="true"
				:caret="false"
				:clear="false"
				:clear-on-select="false"
				:clear-on-search="false"
				:show-options="true"
				:options="getFilteredTags"
				:placeholder="placeholder"
				@search-change="fultextSearch"
				@open="redirectFromDashboard"
				@close="persistSearch"
				class="select-tag--height"
				@select="clearSelect"
				ref="selektor"
				:label="getLabelProp"
			>
				<template v-slot:noresults>
					<span class="multiselect-noresult"> {{$t('nav.no_tags_available')}}</span>
				</template>
			</Multiselect>
			<Multiselect
				v-else-if="areRolesEnabled"
				v-model="tags"
				mode="tags"
				:searchable="true"
				:caret="false"
				:clear="false"
				:clear-on-select="false"
				:clear-on-search="false"
				:show-options="true"
				:options="getFilteredRoles"
				:placeholder="placeholder"
				@search-change="fultextSearch"
				@close="persistSearch"
				class="select-tag--height"
				@select="clearSelect"
				ref="selektor"
				:label="getLabelProp"
			>
				<template v-slot:noresults>
					<span class="multiselect-noresult"> {{$t('nav.no_tags_available')}}</span>
				</template>
			</Multiselect>
			<Multiselect
				v-else
				v-model="inputText"
				mode="single"
				:searchable="true"
				:caret="false"
				:clear="false"
				:clear-on-select="false"
				:clear-on-search="false"
				:placeholder="placeholder"
				:show-options="false"
				@search-change="fultextSearch"
				@close="persistSearch"
				class="select-tag--height"
				@select="clearSelect"
				ref="selektor"
				:label="getLabelProp"
			>
				<template v-slot:noresults>
					<span class="multiselect-noresult"> {{$t('nav.no_tags_available')}}</span>
				</template>
			</Multiselect>
			<i class="icon icon--search"></i>
			<!-- <button type="submit"></button> -->
		</div>
    </div>
</template>

<style>
	.multiselect {
		width: 100%;
	}
	.multiselect.is-open .multiselect-placeholder {
		display: none;
	}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
import SearchBarItem from '@/components/Nav/SearchBarItem.vue';
import {persistSearchTerm, pushSearchTerm, setSearchTags, setSearchTerm, state as appState} from "@/store/app";
import {getFilteredTags} from '@/store/tags';
import {state as roleState} from '@/store/roles';
import router from "@/router";
import {$t, getLanguage} from "@/config";

export default defineComponent({
    name: 'searchBar',
    components: { SearchBarItem },
    data () {
        return {
            show: false,
            //inputText: '',
            showSearch: false,
        }
    },

	computed: {
		getLabelProp(): string { return 'name_' + appState.language },
		tags: {
			get(): any { return appState.searchTags; },
			set(value: any) { setSearchTags(value); }
		},

		areTagsEnabled(): boolean {
			return this.activeItem.route !== '/tag' && this.activeItem.route !== '/user'
		},
		areRolesEnabled(): boolean {
			return this.activeItem.route === '/user'
		},

		getFilteredRoles() {
			return roleState.data
		},

		placeholder() { return appState.persistentSearch=="" ? $t('tag_select.searchbar_placeholder') : appState.persistentSearch },

		getFilteredTags() { return getFilteredTags.value },
    	activeItem() {
    		return appState.searchbarItems.find((item) => item.route === router.currentRoute.value.path) || appState.searchbarItems[0]
		},
		searchbarItems() { return appState.searchbarItems },
		inputText: {
			get(): string { return appState.search; },
			set(value: string) { setSearchTerm(value); }
		}
	},
	methods: {
		clearSelect() {
			//@ts-ignore
			this.$refs.selektor.search = ''
		},
		fultextSearch(value) {
			setSearchTerm(value)
		},

		redirectFromDashboard() {
			if (router.currentRoute.value.path === '/dashboard') {
				router.push('/product')
			}
		},

		persistSearch() {
			persistSearchTerm()
			this.$nextTick(() => {
				this._persistSearchCallback()
			});

		},

		_persistSearchCallback() {
			pushSearchTerm();
		},

		showDropDown () {
			if (this.showSearch) {
				this.show = false
			} else {
				this.show = !this.show
			}
		},
		hideDropDown () {
			this.show = false
		},
		handleInput () {
			if (this.inputText) {
				//this.showSearch = true
				this.show = false
			} else {
				//this.showSearch = false
			}
		},
		handleInputFocus () {
			this.showSearch = false
			//this.inputText = ''
		},
		gotoRoute(route) {
			this.show = false
			router.push(route)
		}
	}
})
</script>
