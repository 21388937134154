

import { defineComponent } from "vue"
import {state as appState, setLanguage} from "@/store/app"

export default defineComponent({
    name: 'LangBar',
	data () {
		return {
			show: false,
		}
	},
    computed: {
		language() { return appState.language; }
	},
	methods: {
        setLanguage(lang) {
			setLanguage(lang)
		},
		toggleDropDown () {
			this.show = !this.show
		},
		hideDropDown () {
			this.show = false
		},
	}
})
