<template>
    <div class="dash-card dash-card--store">
        <div class="dash-card__title">
            <div><h3>{{$t('dashboard.storage')}} <strong>{{ appData.total_human }}</strong></h3></div>
            <div>
                <strong>{{ appData.file_count }}</strong>
                <span>{{$t('dashboard.files')}}</span>
            </div>
        </div>
        <div class="pie-block">
            <span class="pie-chart">
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" class="pie" viewBox="0 0 100 100">
                    <circle class="circle_1" r="42" cx="50" cy="50" stroke-linecap="round"/>
                    <circle class="circle_2" r="42" cx="50" cy="50" transform="rotate(-90 ) translate(-100 0)" stroke-linecap="round" stroke-dasharray="315" stroke-dashoffset="165"/>
                </svg>
                <span class="pie-chart__title"><span>{{ 100 - appData.free_percent }}<sub>%</sub></span><span>{{ $t('dashboard.used') }}</span></span>
            </span>
        </div>
        <div class="dash-card__body">
            <ul class="store-list">
                <li class="store-list__item store-list__item--green">
                    <span>{{ $t('dashboard.remaining') }}</span>
                    <span class="green">{{ appData.free_human }}</span>
                </li>
                <li class="store-list__item store-list__item--red">
                    <span>{{ $t('dashboard.used') }}</span>
                    <span>{{ appData.used_human }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { state as appState } from "@/store/app";

export default defineComponent({
	name: 'Store',
	computed: {
		appData() { return appState.appData }
	}
})

</script>
