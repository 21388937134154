

import { defineComponent } from 'vue'
import {state as tagState, getFilteredTags} from '@/store/tags'

import TagModal from '@/views/Tags/TagModal.vue'
import router from "@/router";
import {getFilteredBrands, state as brandsState} from "@/store/brands";
import {state as appState} from "@/store/app"
import {state as productsState} from "@/store/products";

export default defineComponent({
    name: 'TagList',
	components: { TagModal },
    data () {
        return {
            allSelected: false,
            tagIds: [],
			showContextMenuView: false,
			panelVisible: false,
		}
    },
	mounted() {

	},
	watch: {
    	currentType(newVal, oldVal) {},
		tagIds(newVal, oldVal) {
    		if (newVal.length != this.filteredTags.length) {
    			this.allSelected = false
			} else if (newVal.length === this.filteredTags.length) {
				this.allSelected = true
			}
		}
	},
	computed: {
    	filteredTags() {
			let result = getFilteredTags.value

			const searchTerm = appState.search || appState.persistentSearch

			if (searchTerm) {
				result = result.filter((item) =>
					(item.name?.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase().search(searchTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase()) ?? -1) >= 0
				)
			}

    		return result
		},
    	filteredBrands() { return getFilteredBrands },
	},
    methods: {
		selectAll () {
            if (this.allSelected) {
				this.tagIds = [];
			} else {
				// @ts-ignore
				this.filteredTags.forEach(item => {this.tagIds.push(item.id)})
                // this.productlist.forEach(item => {
                //     // this.productIds.push(item.id)
                // });
            }
        },
    	brands(items) {
			return items.map(item => this.brand(item))
		},
    	brand(item) {
    		const brnd = this.filteredBrands.value.find(brand => brand.id === item)
    		return brnd && brnd.label
		},
		openModal (item) {
			router.push({ name: 'TagEdit', params: { id: item.id, modalItem: item }, })
		},
		openView (e) {
			e.preventDefault()
			this.showContextMenuView = !this.showContextMenuView
		},
	}
})

