import { sentryUrl, translations } from './config'
import './utils/functions'
import { createApp } from 'vue'

import App from './App.vue'
import Harlem from '@harlem/core';
import createDevtoolsPlugin from '@harlem/plugin-devtools';
import router from './router'

import VueClickAway from "vue3-click-away";
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import Multiselect from '@vueform/multiselect'

import Toast, { PluginOptions, POSITION } from "vue-toastification"
import "vue-toastification/dist/index.css";
const options: PluginOptions = {
    position: POSITION.TOP_RIGHT
};

import './registerServiceWorker'
import BackendAPI from "./api/BackendAPI";
import {state as appState} from "./store/app";
import {$t} from "./config"

const token = localStorage.getItem('jwt-token')
if (token) {
	BackendAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

const devtoolsPlugin = createDevtoolsPlugin({
	label: 'My State'
});

const app = createApp(App)
app.use(Harlem, {
	plugins: [devtoolsPlugin]
})
app.mixin( {
	methods: {
		$t,
	}
})
app.use(router)

app.use(VueClickAway)
app.use(PerfectScrollbar)
app.use(Toast, options)
app.component('Multiselect', Multiselect)

app.mount('#app')
