import {RoleData} from "@/types";


interface State {
	data: RoleData[]
}

const state: State = {
	data: []
}

export default state
