<template>
	<modal :open="open" @close="close" class="modal-mask--center">
		<div class="modal modal--popup">
			<button @click="close" class="modal-close-button"><i class="icon icon--cross"> {{$t('popup.close')}}</i></button>
			<div class="modal-container">
				<div class="modal-header">
					<h2>{{$t('popup.forgotten_password_heading')}}</h2>
				</div>
				<div class="modal-body">
					<form @submit.prevent="handleForgotten">
						<div class="form__item form__item--login m-2-0-0-0">
							<label for="userEmail" hidden>{{$t('popup.enter_email')}}</label>
							<input id="userEmail" type="email" v-model="form.email" name="email" :placeholder="$t('popup.forgotten_password_placeholder')" required>
						</div>
						<div class="flex justify-content--center m-2-0-0-0">
							<button class="btn btn--submit btn--shadow btn--radius-5 btn--red btn--width-180" type="submit">
								<span class="btn__text btn__text--white">{{$t('popup.send')}}</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
    </modal>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';
import BackendAPI from "@/api/BackendAPI";
import {updateCurrentData} from "@/store/modal";
import {useToast} from "vue-toastification";
import {$t} from "@/config";

export default {
	name: 'ForgottenPasswordPopup',
	emits: ['closeModal'],
	created() {
		this.toast = useToast()
	},
	props: {
		open: {
			type: Boolean,
			default: false
		},
	},
	components: { Modal },
	data () {
		return {
			modalOpen: false,
			toast: null,
			form: {
				email: "",
			}
		}
	},
	methods: {
		close() {
			this.$emit('closeModal')
			this.modalOpen = false;
		},
		async handleForgotten() {
			try {
				const response = await BackendAPI.post("/auth/forgotten", this.form)

				if (response.status !== 200) {
					console.error(response)
					this.toast.error($t('popup.password_reset_failed'))
					return false
				}
				this.close()

				this.toast.success(response.data.message)

				return true
			} catch (error) {
				console.error(error)
				this.toast.error($t('popup.password_reset_failed'))
				return false
			}
		}
	}
}
</script>
