<template>
	<router-view></router-view>
	<div v-if="loading" id="splash">
		<div>
			<span class="spinning-loader spinning-loader--fat"></span>
		</div>
	</div>
</template>

<style lang="scss">
	@import '@/assets/styles/index.scss';
	.Vue-Toastification__container.top-right,
	.Vue-Toastification__container.top-center {
		margin-top: 75px;
	}
</style>

<script lang="ts">
import {defineComponent} from 'vue'
import {preload, state as appState} from "@/store/app";

export default defineComponent({

	computed: {
		loading() { return appState.loading }
	},

	async created() {
		await preload()
	}
})

</script>
