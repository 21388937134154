
import { defineComponent } from 'vue'

import {loadData, removeUser, saveData, state as usersState} from "@/store/users";
import { state as authState } from "@/store/auth";
import { state as brandsState } from "@/store/brands"
import { state as rolesState } from "@/store/roles"
import { UserData } from "@/types";
import { isAdmin } from "@/store/auth"
import TagSelect from '@/components/Select/TagSelect.vue'
import WarningPopup from "@/components/Popup/WarningPopup.vue"
import {getImagePreview} from '@/utils/utils';
import router from "@/router";

export default defineComponent({
	name: 'UserProfile',
	components: { TagSelect, WarningPopup },
	props: {
		id: {
			type: Number,
			required: true
		},
		full: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	computed: {
		canRemove() {
			//@ts-ignore
			return isAdmin && this.id && this.id !== authState.data.id
		},
		availableBrands() { return brandsState.data },
		availableRoles() { return rolesState.data },
		isAdmin() { return isAdmin.value },
	},


	data(): {
		itemIndex: number,
		data: UserData,
		loading: boolean,
		avatar: string|File|null,
		removeId: Number|null,
	} {
		return {
			data: {
				id: null,
				avatar: "",
				company_name: "",
				name: "",
				surname: "",
				email: "",
				phone: "",
				position: "",
				title_before: "",
				title_after: "",
				roles: [],
				brands: [],
				oldPassword: '',
				newPassword: '',
				newPassword2: '',
			},
			avatar: '',
			itemIndex: 0,
			loading: false,
			removeId: null,
		}
	},

	async mounted() {
		if (usersState.data.length === 0) {
			await loadData()
		}

		const userId = this.id || authState.data.id

		this.itemIndex = usersState.data.findIndex((item) => userId === item.id )
		this.data = Object.assign(this.data, usersState.data[this.itemIndex])
	},

	methods: {
		getImagePreview,
		async removeUser() {
			const result = await removeUser(this.data.id)
			this.removeId = null
			if (result) {
				router.push('/user')
			}
		},
		setImage(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (! files.length) {
				return
			}
			this.avatar = files[0]
		},
		discardChanges() {
			Object.assign(this.data, usersState.data[this.itemIndex])
		},

		cancel() {
			if (this.id) {
				router.push('/user')
			} else {
				router.push('/dashboard')
			}
		},

		async saveChanges() {
			this.loading = true
			const result = await saveData(this.itemIndex, this.data, this.avatar)
			this.loading = false

			if (! result) {
				return;
			}

			if (this.id) {
				router.push('/user')
			} else {
				router.push('/dashboard')
			}
		},
	}

})
