
import { defineComponent } from 'vue'
import Modal from '@/components/Modal/Modal.vue';
import {
	state as modalState,
	openProductModal, closeModal,
} from '@/store/modal'

export default defineComponent({
	name: 'profileModal',
	components: { Modal },
	emits: ['close'],
	props: [
		'modalItem',
		'show',
		'open',
	],
	data () {
		return {
		}
	},

	computed: {
		modalOpen(): boolean {
			return this.open
			//return modalState.open
		},
	},

	methods: {
		close() {
			this.$emit('close')
		}
		// show () {
		// 	this.modalOpen = true;
		// },
		// close () {
		// 	this.modalOpen = false;
		// }
	}
})
