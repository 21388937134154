import BackendAPI from "@/api/BackendAPI"
import {state} from "./store"
import {pushUserData, addItem, updateItem, deleteItem} from "@/store/users/mutations";
import { $t } from '@/config'
import {useToast} from "vue-toastification";

const toast = useToast()

export async function loadData() {
	try {
		const response = await BackendAPI.get('/user/find')

		if (response.status !== 200) {
			toast.error($t('modal.failed_to_load_data'))
			return false
		}

		pushUserData(response.data.data)
	} catch (e) {
		toast.error($t('modal.failed_to_load_data'))
	}
}

export async function removeUser(id) {
	try {
		const response = await BackendAPI.post("/user/remove/" + id, {}, {
			'headers': {
				'Accept': 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		})

		if (response.status !== 200) {
			toast.error($t('modal.failed_to_load_data'))
			return false
		}
		toast.success($t('modal.change_saved'))
		deleteItem(id)

		return true
	} catch (error) {
		toast.error($t('modal.failed_to_load_data'))
		return false
	}
}

export async function saveData(index, data, avatar) {
	try {

		let formData = new FormData()
		formData.append(`avatar`, avatar)
		formData.append('data', JSON.stringify(data))

		const response = await BackendAPI.post("/user/update/" + data.id, formData, {
			'headers': {
				'Accept': 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		})

		if (response.status !== 200) {
			toast.error($t('modal.failed_to_load_data'))
			return false
		}
		toast.success($t('modal.change_saved'))
		updateItem({index, data})

		return true
	} catch (error) {
		toast.error($t('modal.failed_to_load_data'))
		return false
	}
}

export async function addData(data, avatar) {
	try {
		let formData = new FormData()
		formData.append(`avatar`, avatar)
		formData.append('data', JSON.stringify(data))

		const response = await BackendAPI.post("/user/add", formData, {
			'headers': {
				'Accept': 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		})

		if (response.status !== 200) {
			toast.error($t('modal.failed_to_load_data'))
			return false
		}

		addItem({data})
		toast.success($t('modal.change_saved'))
		return true
	} catch (error) {
		toast.error($t('modal.failed_to_load_data'))

		return false
	}
}
