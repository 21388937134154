import BackendAPI from "@/api/BackendAPI"
import {state} from "./store"
import {pushBrandData} from "@/store/brands/mutations";
import {useToast} from "vue-toastification";
import { $t } from '@/config'

const toast = useToast()

export async function loadBrandData() {
	try {
		const response = await BackendAPI.get('/brand/find')

		if (response.status !== 200) {
			console.error(response)
			toast.error($t('brands.loading_failed'))
			return false
		}

		pushBrandData(response.data.data)

		//replaceBrand(response.data.data)
		//http://api.db.orkla.test/files/products/1/products_miniatures_1.png
	} catch (e) {

	}
}
