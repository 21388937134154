<template>
    <div>
        <div class="wrapper wrapper--dash wrapper--dash--pt-0 wrapper--dash--pb-05">
            <h2 class="title">{{$t('dashboard.latest_products_heading')}}</h2>
        </div>
        <div class="table-container">
            <div v-if="data && data.length > 0" class="table" :class="layout == 'grid' ? 'table--grid': 'table--list'">
                <div v-if="layout == 'grid'" class="grid">
                    <div class="card" v-for="(item, index) in data" :key="index" :class="item.type == 'video' || item.type == 'recipe' || item.type == 'photo' ? 'card--full-img' : ''" @click="openModal(item)">
                        <div class="card__img">
                            <img loading="lazy" v-if="item.thumbnail" :src="item.thumbnail" :alt="item.title" :title="item.title" width="315" height="240">
                            <div class="card__label">
                                <span v-for="version in item.versions" :key="version" class="label label--card">{{ version }}</span>
                            </div>
                        </div>
                        <div class="card__row">
                            <div class="card__col card__col--checkbox" v-show="choiceVisible">
                                <div class="form__item form__item--checkbox">
                                    <input type="checkbox" :value="item.id" v-model="productIds" @click.stop>
                                    <span></span>
                                </div>
                            </div>
                            <div class="card__col card__col--text">
                                <div class="card__title"><strong>{{ item.name }}</strong></div>
                                <div class="card__info"><span>{{ item.size }}</span> <span>{{ item.created_at_formatted }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">

import { defineComponent } from 'vue'
import router from "@/router";
import BackendAPI from "@/api/BackendAPI";
import {setCurrentId} from "@/store/brands";

export default defineComponent({
	name: 'ViewedProducts',
    components: {},
    data () {
		return {
			layout: 'grid',
			choiceVisible: false,
			allSelected: false,
			productIds: [],
			modalItem: null,
			showContextMenuView: false,
			panelVisible: false,
			contextMenuItem: null,
			contextMenuIndex: null,
			downloading: "",
			deleteId: null,
			loading:true,
			data: [],
		}
	},
    async created() {

		const response = await BackendAPI.get('/product/dashboard/product')

		if (response.status !== 200) {
			console.error(response)
			return false
		}

		this.data = response.data.data
	},
    mounted() {},
    methods: {
		openModal(item) {
			setCurrentId(item.brand_id)
			router.push('/' + item.type + '/detail/' + item.id)
		},
    },
})

</script>

