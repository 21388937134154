import { getter, state } from './store';

export const isAuthenticated = getter('isAuthenticated', (state) => !!state.token)

export const userData = getter('userData', state => state.data)

export const isAdmin = getter('auth-is-admin', state => { return state.data.roles.includes(2) || state.data.roles.includes(3) })
export const isSuperAdmin = getter('auth-is-superadmin', state => {return state.data.roles.includes(3)})
export const isExternal = getter('auth-is-external', state => {return state.data.roles.includes(4)})

export const hasPermission = function(resource: string): Boolean
{
	return isAdmin.value === true || state.data.permissions.includes('*') || state.data.permissions.includes(resource)
}

export const isInRole = function(role: number): Boolean {
	return state.data.roles.includes(role)
}
