<template>
    <div>
        <h2>404 Not Found</h2>
    </div>
</template>

<script>
export default {

}
</script>