<template>
	<form class="form-profile">
		<perfect-scrollbar>
			<div class="wrapper">
				<div class="form-profile__header flex align-items--center justify-content--space-between">
					<h2>{{$t('user.add_user')}}</h2>
					<a @click="cancel" :title="$t('user.discard_changes')" class="flex align-items--center">
						<span class="p-0-1-0-0">{{$t('user.discard_changes')}}</span>
						<span class="btn-rouned align-items--center justify-content--center"><i class="icon icon--arrow icon--cross">{{$t('user.discard_changes')}}</i></span>
					</a>
				</div>
				<div class="flex flex-row--sm flex-row--xl m-2-5-0">
					<div class="flex-col--7 flex-col-xl-0-1-25-0-0">
						<div class="m-0-0-2-5-0">
							<div class="dash-card dash-card--profile flex flex-row--sm flex-row--md justify-content--start justify-content-md--start align-items--center">
								<div class="m-md-0-1-25-0-0">
									<div class="dash-card__img">
										<img v-if="this.avatar" :src="getImagePreview(this.avatar)" alt="New image" width="155" height="155">
									</div>
								</div>
								<div>
									<div class="form-group-item">
										<div class="form__item form__item--profile form__item--w-61">
											<label for="">{{$t('user.title_before')}}</label>
											<input type="text"  v-model="data.title_before">
										</div>
										<div class="form__item form__item--profile">
											<label for="">{{$t('user.name')}}</label>
											<input class="form__item--museo-700" type="text" v-model="data.name">
										</div>
										<div class="form__item form__item--profile">
											<label for="">{{$t('user.surname')}}</label>
											<input class="form__item--museo-700" type="text" v-model="data.surname">
										</div>
										<div class="form__item form__item--profile form__item--w-61">
											<label for="">{{$t('user.title_after')}}</label>
											<input type="text" v-model="data.title_after">
										</div>
									</div>
									<div v-if="this.avatar" class="flex flex-column flex-row-md m-1-5-0-0-0">
										<div class="m-1-0-0-0">
											<button @click.stop="this.avatar = null" class="btn-simple btn-simple--delele btn-simple--icon-left">
												<i class="icon icon--trash-gray"></i>
												<span class="btn-simple__text btn-simple__text--size-13 btn-simple__text--color-gray">{{$t('user.discard_selection')}}</span>
											</button>
										</div>
									</div>
									<div v-else class="row flex justify-content--space-between m-1-5-0-0-0">
										<div class="form__item form__item--upload">
											<input @change="setImage" id="profileThumb" type="file">
											<label for="profileThumb">
												<span>{{$t('user.new_photo')}}</span>
												<i class="icon icon--upload-red"></i>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-2-5-0-0-0">
							<div class="dash-card dash-card--profile">
								<div class="form-control-row form-control-row--48">
									<div class="form__item form__item--profile">
										<TagSelect :label="$t('user.brands')" :options="availableBrands" v-model="data.brands" />
										<TagSelect :label="$t('user.roles')" :options="availableRoles" v-model="data.roles" />
									</div>
									<div class="form__item form__item--profile">
										<label for="">{{$t('user.company')}}</label>
										<input type="text" v-model="data.company_name">
									</div>
									<div class="form__item form__item--profile">
										<label for="">{{$t('user.position')}}</label>
										<input type="text" v-model="data.position">
									</div>
								</div>
								<div class="form-control-row form-control-row--48 form-control-row--m-2-0-0-0">
									<div class="form__item form__item--profile">
										<label for="">{{$t('user.phone')}}</label>
										<input type="text" v-model="data.phone">
									</div>
									<div class="form__item form__item--profile">
										<label for="">{{$t('user.email')}}</label>
										<input type="text" v-model="data.email">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-profile__footer row flex justify-content justify-content--end align-items--center">
					<p class="flex-row-p-0-1-0-0">{{$t('user.post_registration_notice')}}&nbsp; </p>
					<button
						@click.prevent="saveChanges"
						type="submit"
						class="btn btn--submit btn--shadow btn--radius-5 btn--red"
					>
						<span class="btn__text btn__text--white">{{$t('user.save_changes')}}</span>
					</button>
				</div>
			</div>
		</perfect-scrollbar>
	</form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { addData } from "@/store/users";
import { state as brandsState} from "@/store/brands"
import { state as rolesState } from "@/store/roles"
import { UserData } from "@/types";
import {$t} from "@/config";
import TagSelect from '@/components/Select/TagSelect.vue'
import {useToast} from "vue-toastification";
import router from "@/router";
import {getImagePreview} from '@/utils/utils';

const toast = useToast()

export default defineComponent({
	name: 'UserAdd',
	components: { TagSelect },
	props: {
		id: {
			type: Number,
			required: true
		}
	},

	computed: {
		availableBrands() { return brandsState.data },
		availableRoles() { return rolesState.data },
	},

	data(): {
		data: UserData,
		avatar: string
	} {
		return {
			data: {
				id: null,
				avatar: "",
				name: "",
				surname: "",
				company_name: "",
				email: "",
				phone: "",
				position: "",
				title_before: "",
				title_after: "",
				roles: [],
				brands: [],
				oldPassword: '',
				newPassword: '',
				newPassword2: '',
			},
			avatar: '',
		}
	},

	async mounted() {
		this.data = Object.assign(this.data, {
			id: null,
			avatar: "",
			name: "",
			surname: "",
			company_name: "",
			email: "",
			phone: "",
			position: "",
			title_before: "",
			title_after: "",
			roles: [],
			brands: [],
			oldPassword: '',
			newPassword: '',
			newPassword2: '',
		})
	},

	methods: {
		$t,
		getImagePreview,
		discardChanges() {
			Object.assign(this.data, {
				id: null,
				avatar: "",
				name: "",
				surname: "",
				company_name: "",
				email: "",
				phone: "",
				position: "",
				title_before: "",
				title_after: "",
				roles: [],
				brands: [],
				oldPassword: '',
				password: '',
				passwordAgain: '',
			})
			this.avatar = ''
		},

		setImage(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (! files.length) {
				return
			}
			this.avatar = files[0]
		},

		async saveChanges() {


			if (this.data.email.length < 1) {
				toast.error($t('user.error_email'))
				return false
			}
			if (this.data.name.length < 1) {
				toast.error($t('user.error_name'))
				return false
			}
			if (this.data.surname.length < 1) {
				toast.error($t('user.error_surname'))
				return false
			}

			if (this.data.roles.length < 1) {
				toast.error($t('user.error_roles'))
				return false
			}

			console.log(this.avatar)
			if (await addData(this.data, this.avatar)) {
				router.push('/user')
			}
		},

		cancel() {
			router.push('/user')
		},
	}

})
</script>
