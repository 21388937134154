import { arrayToObject } from '@/utils/utils'
import {state as appState} from "@/store/app";

function getConfig(key: string) {
  //const CONFIG = window.CONFIG !== undefined ? window.CONFIG || process.env
  const config = process.env
  if (config[key]) {
    return config[key]
  } else {
    return null
  }
}

export const nodeEnv = getConfig('NODE_ENV') || 'production' // development | test | production
export const vueEnv = getConfig('VUE_APP_ENV') || 'PROD' // DEV | TEST | PROD
export const sentryUrl = getConfig('VUE_APP_SENTRY_URL')
export const backendUrl = getConfig('VUE_APP_BACKEND_URL')
export const backendBaseUrl = getConfig('VUE_APP_BACKEND_BASE_URL')
export const baseUrl = getConfig('BASE_URL') || '/'

export const PAGINATION_LIMITS = [3, 20, 50, 100]
export const PAGINATION_LIMIT_DEFAULT = 20
export const PAGINATION_SORT_BY_DEFAULT = 'timeCreatedNum'
export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm'

export const paginationLimitOptions = [
  {
    label: '25 ',
    value: 25,
  },
  {
    label: '50 ',
    value: 50,
  },
  {
    label: '100 ',
    value: 100,
  },
  {
    label: '200 ',
    value: 200,
  },
  {
    label: '500 ',
    value: 500,
  },
]

export const userRoles = [
  {
    id: 'user',
    label: 'User',
  },
  {
    id: 'admin',
    label: 'Admin',
  },
]

export const getLanguage = function(): string {
	const lang = localStorage.getItem('language') || navigator.language.substr(0,2).toLowerCase()
	return appState?.language || ['cs','sk'].includes(lang) ? 'cs' : 'en'
}

export const $t = function(key) {
	let ret = translations[appState?.language || getLanguage()]
	const keys = key.split('.')
	for (let k of keys) {
		ret = ret.hasOwnProperty(k) ? ret[k] : key
	}

	if (Array.isArray(ret) || typeof ret === 'object') {
		return ret
	}


	console.log()

	return ret
}

export const translations = {
	'cs': {
		'user': {
			'logout': 'Odhlásit',
			'my_account': 'Můj účet',
			'add_user': 'Přidat uživatele',
			'password_change': 'Změna hesla',
			'discard_changes': 'Zrušit změny',
			'save_changes': 'Uložit změny',
			'discard_selection': 'Zrušit výběr',
			'title_before': 'Titul před',
			'name': 'Jméno',
			'surname': 'Příjmení',
			'title_after': 'Titul za',
			'new_photo': 'Nová fotografie',
			'delete_photo': 'Smazat fotografii',
			'brands': 'Značky',
			'roles': 'Role',
			'company': 'Společnost',
			'position': 'Pozice',
			'phone': 'Telefon',
			'email': 'Email',
			'old_password': 'Staré heslo',
			'new_password': 'Nové heslo',
			'new_password_again': 'Nové heslo zopakujte',

			'error_email': 'Zadejte email',
			'error_name': 'Zadejte jméno',
			'error_surname': 'Zadejte příjmení',
			'error_roles': 'Vyberte roli',

			'name_surname': 'Jméno a přijmení',
			'last_login': 'Poslední přihlášení',
			'registration_date': 'Datum registrace',
			'edit_details': 'Editovat údaje',


			'remove_user': 'Odstranit uživatele',
			'saving': 'Ukládám',

			'post_registration_notice': 'Po uložení novému uživateli přijde email s informací, jak se má přihlásit.',

		},
		'login': {
			'heading': 'Přihlášení',
			'heading_set_password': 'Nastavení hesla',
			'heading_change_password': 'Změna hesla',
			'back_to_login': 'Zpět na přihlášení',
			'enter_new_password': 'Zadejte nové heslo',
			'enter_password_again': 'A znovu pro ověření',
			'set_password': 'Nastavit heslo',
			'passwords_must_match': 'Hesla se musí shodovat a být alespoň 5 znaků dlouhá.',
			'reset_request_invalid': 'Žádost o reset hesla je neplatná. Zkuste to znovu, nebo kontaktujte podporu.',
			'failed_to_change_password': 'Nepodařilo se změnit heslo, zkuste to znovu.',
			'login_with_new_password': 'Nyní se můžete přihlásit novým heslem.',
			'enter_your_email': 'Zadejte váš email',
			'enter_password': 'Zadejte heslo',
			'enter': 'Vstoupit',
			'cant_login?': 'Nemůžete se přihlásit?',
		},
		'tags': {
			'add_tags': 'Přidat tagy',
			'global_tag': 'Globální tag',
			'brand': 'Značka',
		},
		'dashboard': {
			'welcome_heading': 'Vítejte v Orkla databázi',
			'latest_products_heading': 'Poslední nahrané produkty',
			'unknown_type': 'Neznámý typ',
			'storage': 'Uložiště',
			'files': 'Souborů',
			'used': 'Využito',
			'remaining': 'Využito',
			'other_brands': 'Další značky',
			'other_br_brands': 'Další<br> značky',
		},
		'input': {
			'default_placeholder': 'Zadejte název',
		},
		'single_select': {
			'default_label': 'Přiřadit',
			'default_placeholder': 'Vyberte',
		},
		'tag_select': {
			'no_results': 'Žádné tagy k přiřezení',
			'assign_tags': 'Přiřadit tagy',
			'delete_tag': 'Smazat tag',
			'search': 'Vyhledávání',
			'placeholder': 'Vyhledat nebo přidružit nový soubor',
			'searchbar_placeholder': "Název, EAN, SAP ID, tagy",
			'title': 'Název',
			'title_en': 'Název - EN',
			'title_cs': 'Název - CZ',
			'created': 'Vytvořeno',
			'global': 'Globální',
			'usage': 'Použití',
			'section': 'Sekce',
			'delete': 'Vymazat',
			'add': 'Přidat',
			'yes': 'ANO',
			'no': 'NE',
			'add_tag': 'Přidat tag',
			'edit_tag': 'Upravit tag',
			'discard_changes': 'ZRUŠIT ZMĚNY',
			'save_changes': 'Uložit změny',
			'saving_changes': 'Ukládám změny',
		},
		'modal': {
			'close': 'Zavřít',
			'back': 'Zpět',
			'select_brand': 'Vyberte značku',
			'files': 'Soubory',
			'tags': 'Tagy',
			'users': 'Uživatelé',
			'search': 'Vyhledávání',
			'search_placeholder': 'Název, EAN, ID souboru',
			'failed_to_store_change': 'Nepodařilo se uložit změnu',
			'failed_to_load_data': 'Nepodařilo se načíst data',
			'change_saved': 'Změna byla uložena.',
		},
		'auth': {
			'login_failed': 'Nepodařilo se přihlásit',
		},
		'brands': {
			'loading_failed': 'Nepodařilo se načíst značky.',
		},
		'searchbar': {
			'products': 'Vyhledat produkt',
			'archive': 'Vyhledat v archivu',
			'videos': 'Vyhledat video',
			'recipes': 'Vyhledat recept',
			'photos': 'Vyhledat fotku',
			'tags': 'Vyhledat tag',
			'users': 'Vyhledat uživatele',
		},
		'nav': {
			'no_tags_available': 'Žádné tagy k přiřezení',
			'placeholder': 'Název, EAN, SAP ID, tagy',
			'products': 'Produkty CZ',
			'products_sk': 'Produkty SK',
			'products_hu': 'Produkty HU',
			'archive': 'Archiv',
			'videos': 'Videa',
			'recipes': 'Recepty',
			'photos': 'Fotky',
			'user_management': 'Správa uživatelů',
			'tag_management': 'Správa tagů',
		},
		'type': {
			'product': 'Produkty',
			'video': 'Videa',
			'recipe': 'Recepty',
			'photo': 'Fotky',
			'product_sk': 'Produkty SK',
			'product_hu': 'Produkty HU',
		},
		'popup': {
			'close': 'Zavřít',
			'file_deleted': 'Soubor smazán',
			'selected_files_deleted': 'Vybrané soubory byly úspěšně smazány ze systému.',
			'forgotten_password_heading': 'Nemůžete se přihlásit?',
			'forgotten_password_placeholder': 'Zadejte váš email...',
			'enter_email': 'Zadejte váš email',
			'send': 'Odeslat',
			'password_reset_failed': 'Nepodařilo se obnovit heslo, kontaktujte podporu.',
			'success': 'Úspěch',
			'it_worked': 'Podařilo se...',
			'delete_confirmation': 'Potvrzením nenávratně odstraníte vybranou položku. Přejete si pokračovat?',
			'warning': 'Varování',
			'cancel': 'Zrušit',
			'remove': 'Smazat',
		},
		'image_list': {
			'variants_for_download': 'Varianty ke stažení',
			'download_all': 'STÁHNOUT VŠE',
		},
		'products': {
			'latest': 'Nejnovější',
			'title': 'Název',
			'upload_date': 'Datum nahrání',
			'select_all': 'Vybrat vše',
			'selection': 'Výběr',
			'download_all': 'Stáhnout vše',
			'rename': 'Přejmenovat',
			'share': 'Sdílet',
			'other_formats': 'Další formáty',
			'delete': 'Smazat',
			'cancel': 'Zrušit',
			'close': 'Zavřít',
			'back': 'Zpět',
			'archive': 'ARCHIV',
			'drag_to_upload': 'Přetažením nahraj',
			'drag_files': 'Přetáhněte soubory',
			'drag_more_files': 'Přetáhněte další soubory',
			'upload_files': 'Nahrát soubory',
			'uploading_files': 'Nahrávám soubory',
			'loading': 'Nahravám',
			'add_ean': 'Přidat EAN',
			'add_sap_id': 'Přidat SAP ID',
			'discard_changes': 'ZRUŠIT ZMĚNY',
			'save_changes': 'Uložit změny',
			'filename': 'Název souboru',
			'assigned_products': 'Přiřazené produkty',
			'unknown_type': 'Neznámý typ',
			'upload_queue': 'Fronta souborů k nahrání',
			'select_category': 'Vyber kategorii',
			'no_products_to_assign': 'Žádné produkty k přiřezení',
			'no_products_found': 'Nebyly nalezeny žádné produkty.',
			'export_selected_copy': 'Exportovat vybrané texty',
			'export_selected_videos': 'Exportovat vybraná videa',
			'export_selected_photos': 'Exportovat vybrané fotky',
			'tabs': {
				'video': [
					'Popis videa',
				],
				'recipe': [
					'Ingredience',
					'Příprava receptu',
				],
				'photo': [],
			},
			'default_tabs': [
				'Marketingové texty',
				'Legislativní texty',
			],
			'order_by': 'Seřadit podle',
		},


	},
	'en': {
		'user': {
			'logout': 'Log out',
			'my_account': 'My account',
			'add_user': 'Add user',
			'password_change': 'Password change',
			'discard_changes': 'Discard changes',
			'save_changes': 'Save changes',
			'discard_selection': 'Cancel selection',
			'title_before': 'Title before',
			'name': 'Name',
			'surname': 'Surname',
			'title_after': 'Title after',
			'new_photo': 'New photo',
			'delete_photo': 'Delete photo',
			'brands': 'Brands',
			'roles': 'Roles',
			'company': 'Company',
			'position': 'Position',
			'phone': 'Phone',
			'email': 'Email',
			'old_password': 'Old password',
			'new_password': 'New password',
			'new_password_again': 'New password again',

			'error_email': 'Enter email',
			'error_name': 'Enter name',
			'error_surname': 'Enter surname',
			'error_roles': 'Select role(s)',

			'name_surname': 'Name and surname',
			'last_login': 'Last login',
			'registration_date': 'Date of registration',
			'edit_details': 'Edit details',


			'remove_user': 'Remove user',
			'saving': 'Saving',

			'post_registration_notice': 'New user will receive an email with login information.',
		},
		'login': {
			'heading': 'Login',
			'heading_set_password': 'Password setting',
			'heading_change_password': 'Password change',
			'back_to_login': 'Back to login',
			'enter_new_password': 'Enter new password',
			'enter_password_again': 'And again',
			'set_password': 'Set password',
			'passwords_must_match': 'Passwords must match and be at least 5 characters long.',
			'reset_request_invalid': 'Password reset request is invalid. Try it again or contact support.',
			'failed_to_change_password': 'Password change failed, try it again.',
			'login_with_new_password': 'You can log in with your new password now.',
			'enter_your_email': 'Enter your email',
			'enter_password': 'Enter password',
			'enter': 'Log in',
			'cant_login?': 'Can\'t log in?',
		},
		'tags': {
			'add_tags': 'Add tags',
			'global_tag': 'Global tag',
			'brand': 'Brand',

		},
		'dashboard': {
			'welcome_heading': 'Welcome to the Orkla database',
			'latest_products_heading': 'Latest uploaded products',
			'unknown_type': 'Unknown type',
			'storage': 'Storage',
			'files': 'Files',
			'used': 'Used',
			'remaining': 'Remaining',
			'other_brands': 'Other brands',
			'other_br_brands': 'Other<br> brands',
		},
		'input': {
			'default_placeholder': 'Enter title',
		},
		'single_select': {
			'default_label': 'Assign',
			'default_placeholder': 'Select',
		},
		'tag_select': {
			'no_results': 'No tags available',
			'assign_tags': 'Assign tags',
			'delete_tag': 'Delete tag',
			'search': 'Search',
			'searchbar_placeholder': "Title, EAN, SAP ID, tags",
			'placeholder': 'Look up or assign new file',
			'title': 'Name',
			'title_en': 'Name - EN',
			'title_cs': 'Name - CZ',
			'created': 'Created',
			'global': 'Global',
			'usage': 'Usage',
			'section': 'Section',
			'delete': 'Delete',
			'add': 'Add',
			'yes': 'YES',
			'no': 'NO',
			'add_tag': 'Add tag',
			'edit_tag': 'Edit tag',
			'discard_changes': 'DISCARD CHANGES',
			'save_changes': 'Save changes',
			'saving_changes': 'Saving changes',
		},
		'modal': {
			'close': 'Close',
			'back': 'Back',
			'select_brand': 'Select brand',
			'files': 'Files',
			'tags': 'Tags',
			'users': 'Users',
			'search': 'Search',
			'search_placeholder': 'Title, EAN, File ID',
			'failed_to_store_change': 'Failed to save changes',
			'failed_to_load_data': 'Failed to load data',
			'change_saved': 'Update was successful.',
		},
		'auth': {
			'login_failed': 'Failed to log in',
		},
		'brands': {
			'loading_failed': 'Failed to load brands.',
		},
		'searchbar': {
			'products': 'Search in products',
			'archive': 'Search in archive',
			'videos': 'Search in videos',
			'recipes': 'Seearch in recipes',
			'photos': 'Search in photos',
			'tags': 'Search in tags',
			'users': 'Search in users',
		},
		'nav': {
			'no_tags_available': 'No tags to assign',
			'placeholder': 'Title, EAN, SAP ID, tags',
			'products': 'Products CZ',
			'products_sk': 'Products SK',
			'products_hu': 'Products HU',
			'archive': 'Archive',
			'videos': 'Videos',
			'recipes': 'Recipes',
			'photos': 'Photos',
			'user_management': 'User management',
			'tag_management': 'Tag management',
		},
		'type': {
			'product': 'Products',
			'video': 'Videos',
			'recipe': 'Recipes',
			'photo': 'Photos',
			'product_sk': 'Products SK',
			'product_hu': 'Products HU',
		},
		'popup': {
			'close': 'Close',
			'file_deleted': 'File has been deleted',
			'selected_files_deleted': 'Selected files have been deleted.',
			'forgotten_password_heading': 'Can\'t login?',
			'forgotten_password_placeholder': 'Enter your email...',
			'enter_email': 'Enter your email',
			'send': 'Send',
			'password_reset_failed': 'Failed to recover password, please contact support.',
			'success': 'Success',
			'it_worked': 'It worked...',
			'delete_confirmation': 'You irreversably delete selected item. Do you want to continue?',
			'warning': 'Warning',
			'cancel': 'Cancel',
			'remove': 'Remove',
		},
		'image_list': {
			'variants_for_download': 'Variants for download',
			'download_all': 'DOWNLOAD ALL',
		},
		'products': {
			'latest': 'Latest',
			'title': 'Title',
			'upload_date': 'Upload date',
			'select_all': 'Select all',
			'selection': 'Selection',
			'download_all': 'Download all',
			'rename': 'Rename',
			'share': 'Share',
			'other_formats': 'Other formats',
			'delete': 'Delete',
			'cancel': 'Cancel',
			'close': 'Close',
			'back': 'Back',
			'archive': 'ARCHIVE',
			'drag_to_upload': 'Drag to upload',
			'drag_files': 'Drag files',
			'drag_more_files': 'Drag to upload more files',
			'upload_files': 'Upload files',
			'uploading_files': 'Uploading files',
			'loading': 'Uploading',
			'add_ean': 'Add EAN',
			'add_sap_id': 'ADD SAP ID',
			'discard_changes': 'DISCARD CHANGES',
			'save_changes': 'Save changes',
			'filename': 'File name',
			'assigned_products': 'Assign products',
			'unknown_type': 'Unknown type',
			'upload_queue': 'File upload queue',
			'select_category': 'Select category',
			'no_products_to_assign': 'No products to assign',
			'no_products_found': 'No products found.',
			'export_selected_copy': 'Export selected texts',
			'export_selected_videos': 'Export selected videos',
			'export_selected_photos': 'Export selected photos',
			'tabs': {
				'video': [
					'Video description',
				],
				'recipe': [
					'Ingredients',
					'Recipe preparation',
				],
				'photo': [],
			},
			'default_tabs': [
				'Marketing copy',
				'Legal copy',
			],
			'order_by': 'Order by',

		},
	}
}


if (vueEnv === 'PROD') {
	//console.log = function () {};
}
