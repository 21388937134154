<template>
	<form @submit.stop.prevent class="form-profile" autocomplete="off">
		<perfect-scrollbar>
			<div class="wrapper" id="modals">
				<div class="form-profile__header flex align-items--center justify-content--space-between">
					<h2>{{$t('user.my_account')}}</h2>
					<a @click="cancel" title="Zrušit změny" class="flex align-items--center">
						<span class="btn__text btn__text--black p-0-1-0-0">{{$t('user.discard_changes')}}</span>
						<span class="btn-rouned align-items--center justify-content--center"><i class="icon icon--cross icon--cross-size-12">{{$t('user.discard_changes')}}</i></span>
					</a>
				</div>
				<div class="flex flex-column flex-row-xl m-2-5-0">
					<div class="flex-col--7 p-xl-0-1-25-0-0">
						<div class="m-0-0-2-5-0">
							<div class="dash-card dash-card--profile flex flex-column flex-row-md">
								<div class="m-md-0-1-25-0-0">
									<div class="dash-card__img">
										<img v-if="this.avatar" :src="getImagePreview(this.avatar)" alt="New image" width="155" height="155">
										<img v-else-if="data.avatar" :src="data.avatar" :alt="data.name" width="155" height="155">
									</div>
								</div>
								<div class="m-2-5-0-0-0 m-md-0">
									<div class="form-group-item">
										<div class="form__item form__item--profile form__item--w-61">
											<label>{{$t('user.title_before')}}</label>
											<input type="text" v-model="data.title_before" autocomplete="off">
										</div>
										<div class="form__item form__item--profile">
											<label>{{$t('user.name')}}</label>
											<input class="form__item--museo-700" type="text" v-model="data.name" autocomplete="off">
										</div>
										<div class="form__item form__item--profile">
											<label>{{$t('user.surname')}}</label>
											<input class="form__item--museo-700" type="text" v-model="data.surname" autocomplete="off">
										</div>
										<div class="form__item form__item--profile form__item--w-61">
											<label for="title_after">{{$t('user.title_after')}}</label>
											<input id="title_after" type="text" v-model="data.title_after" autocomplete="off">
										</div>
									</div>
									<div v-if="this.avatar" class="flex flex-column flex-row-md m-1-5-0-0-0">
										<div class="m-1-0-0-0">
											<button @click.stop="this.avatar = null" class="btn-simple btn-simple--delele btn-simple--icon-left">
												<i class="icon icon--trash-gray"></i>
												<span class="btn-simple__text btn-simple__text--size-13 btn-simple__text--color-gray">{{$t('user.discard_selection')}}</span>
											</button>
										</div>
									</div>
									<div v-else class="flex flex-column flex-row-md m-1-5-0-0-0">
										<div class="form__item form__item--upload m-md-0-1-25-0-0">
											<input @change="setImage" id="profile_thumb" type="file">
											<label for="profile_thumb">
												<span>{{$t('user.new_photo')}}</span>
												<i class="icon icon--upload-red"></i>
											</label>
										</div>
										<div class="m-1-0-0-0">
											<button @click.stop.prevent="data.avatar = null" class="btn-simple btn-simple--delele btn-simple--icon-left">
												<i class="icon icon--trash-gray"></i>
												<span class="btn-simple__text btn-simple__text--size-13 btn-simple__text--color-gray">{{$t('user.delete_photo')}}</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-2-5-0-0-0">
							<div class="dash-card dash-card--profile">
								<div v-if="isAdmin" class="flex flex-column flex-row-md justify-content-md--space-between">
									<div class="w-48-md">
										<TagSelect class="select-tag--label-profile" :label="$t('user.brands')" :options="availableBrands" v-model="data.brands" />
									</div>
									<div class="w-48-md">
										<TagSelect class="select-tag--label-profile" :label="$t('user.roles')" :options="availableRoles" v-model="data.roles" />
									</div>
								</div>

								<div class="flex flex-column flex-row-md justify-content-md--space-between">
									<div class="form__item form__item--profile w-48-md m-1-5-0-0-0 m-md-0">
										<label>{{$t('user.company')}}</label>
										<input type="text" v-model="data.company_name" autocomplete="off">
									</div>
									<div class="form__item form__item--profile w-48-md m-1-5-0-0-0 m-md-0">
										<label>{{$t('user.position')}}</label>
										<input type="text" v-model="data.position" autocomplete="off">
									</div>
								</div>

								<div class="flex flex-column flex-row-md justify-content-md--space-between">
									<div class="form__item form__item--profile w-48-md m-1-5-0-0-0">
										<label>{{$t('user.phone')}}</label>
										<input type="text" v-model="data.phone" autocomplete="off">
									</div>
									<div class="form__item form__item--profile w-48-md m-1-5-0-0-0">
										<label>{{$t('user.email')}}</label>
										<input disabled type="text" v-model="data.email" autocomplete="off">
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="flex-col--3 m-2-5-0-0-0 m-xl-0">
						<div class="dash-card dash-card--profile">
							<div class="row">
								<h3>{{$t('user.password_change')}}</h3>
							</div>
							<div v-if="! isAdmin" class="form__item form__item--profile m-2-0-0-0">
								<label for="password">{{$t('user.old_password')}}</label>
								<input autocomplete="off" id="password" type="password" v-model="data.oldPassword" name="password">
							</div>
							<div class="form__item form__item--profile m-2-0-0-0">
								<label for="password_new">{{$t('user.new_password')}}</label>
								<input autocomplete="off" id="password_new" type="password" v-model="data.newPassword" name="password_new">
							</div>
							<div class="form__item form__item--profile m-2-0-0-0">
								<label for="password_confirmed">{{$t('user.new_password_again')}}</label>
								<input autocomplete="off" id="password_confirmed" type="password" v-model="data.newPassword2" name="password_confirmed">
							</div>
						</div>
					</div>
				</div>
				<div class="form-profile__footer flex justify-content--end align-items--center">
					<button
						v-if="!loading && canRemove"
						@click.prevent="removeId = id"
						type="submit"
						class="btn btn--submit btn--shadow btn--radius-5 btn--red"
					>
						<span class="btn__text btn__text--spinner btn__text--white">{{$t('user.remove_user')}}</span>
					</button>&nbsp;

					<button
						v-if="!loading"
						@click.prevent="saveChanges"
						type="submit"
						class="btn btn--submit btn--shadow btn--radius-5 btn--red"
					>
						<span class="btn__text btn__text--spinner btn__text--white">{{$t('user.save_changes')}}</span>
					</button>
					<button
						v-else
						disabled
						@click.prevent=""
						type="submit"
						class="btn btn--submit btn--shadow btn--radius-5 btn--red"
					>
						<span class="btn__text btn__text--spinner btn__text--white">{{$t('user.saving')}}<span class="spinning-loader spinning-loader--white"></span></span>
					</button>
				</div>
			</div>
		</perfect-scrollbar>
	</form>
	<WarningPopup :open="removeId !== null" @delete-item="removeUser" @close-modal="removeId = null"></WarningPopup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import {loadData, removeUser, saveData, state as usersState} from "@/store/users";
import { state as authState } from "@/store/auth";
import { state as brandsState } from "@/store/brands"
import { state as rolesState } from "@/store/roles"
import { UserData } from "@/types";
import { isAdmin } from "@/store/auth"
import TagSelect from '@/components/Select/TagSelect.vue'
import WarningPopup from "@/components/Popup/WarningPopup.vue"
import {getImagePreview} from '@/utils/utils';
import router from "@/router";

export default defineComponent({
	name: 'UserProfile',
	components: { TagSelect, WarningPopup },
	props: {
		id: {
			type: Number,
			required: true
		},
		full: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	computed: {
		canRemove() {
			//@ts-ignore
			return isAdmin && this.id && this.id !== authState.data.id
		},
		availableBrands() { return brandsState.data },
		availableRoles() { return rolesState.data },
		isAdmin() { return isAdmin.value },
	},


	data(): {
		itemIndex: number,
		data: UserData,
		loading: boolean,
		avatar: string|File|null,
		removeId: Number|null,
	} {
		return {
			data: {
				id: null,
				avatar: "",
				company_name: "",
				name: "",
				surname: "",
				email: "",
				phone: "",
				position: "",
				title_before: "",
				title_after: "",
				roles: [],
				brands: [],
				oldPassword: '',
				newPassword: '',
				newPassword2: '',
			},
			avatar: '',
			itemIndex: 0,
			loading: false,
			removeId: null,
		}
	},

	async mounted() {
		if (usersState.data.length === 0) {
			await loadData()
		}

		const userId = this.id || authState.data.id

		this.itemIndex = usersState.data.findIndex((item) => userId === item.id )
		this.data = Object.assign(this.data, usersState.data[this.itemIndex])
	},

	methods: {
		getImagePreview,
		async removeUser() {
			const result = await removeUser(this.data.id)
			this.removeId = null
			if (result) {
				router.push('/user')
			}
		},
		setImage(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (! files.length) {
				return
			}
			this.avatar = files[0]
		},
		discardChanges() {
			Object.assign(this.data, usersState.data[this.itemIndex])
		},

		cancel() {
			if (this.id) {
				router.push('/user')
			} else {
				router.push('/dashboard')
			}
		},

		async saveChanges() {
			this.loading = true
			const result = await saveData(this.itemIndex, this.data, this.avatar)
			this.loading = false

			if (! result) {
				return;
			}

			if (this.id) {
				router.push('/user')
			} else {
				router.push('/dashboard')
			}
		},
	}

})
</script>
