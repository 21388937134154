<template>
    <ul class="dash-list">
        <li class="dash-list__title">
			{{$t('dashboard.latest_products_heading')}}
        </li>

		<li v-for="(item, index) in data" :key="index" class="dash-list-item" @click="openModal(item)">
			<div class="col dash-list-item__img">
                <span>
                    <img :src="item.thumbnail" :alt="item.title" width="48" height="32">
                </span>
			</div>
			<div class="col dash-list-item__title">
				<strong>{{item.name}}</strong>
				<span>{{item.created_at_formatted}}</span>
			</div>
			<div class="col dash-list-item__date">{{item.created_at_formatted}}</div>
			<div class="col dash-list-item__type">{{ typeLabel(item) }}</div>
		</li>
    </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import router from "@/router";
import {$t} from "@/config";

import {
	state as modalState,
	openProductModal,
} from '@/store/modal'

import {
	setCurrentId,
	state as brandsState,
} from '@/store/brands'

import {
	state as productsState,
	loadProductData,
	downloadFileType,
	downloadAll,
	removeProduct
} from '@/store/products'

import {ProductFileType} from "@/types";
import {availableProductTypes} from "@/types/product";
import BackendAPI from "@/api/BackendAPI";

export default defineComponent({
	name: 'ViewedFiles',
	components: {
		// WarningPopup,
		// UploadDropTarget, ContextMenu, ProductModal
	},
	data () {
		return {
			layout: 'grid',
			choiceVisible: false,
			allSelected: false,
			productIds: [],
			modalItem: null,
			showContextMenuView: false,
			panelVisible: false,
			contextMenuItem: null,
			contextMenuIndex: null,
			downloading: "",
			deleteId: null,
			loading:true,
			data: [],
		}
	},

	async created() {

		const response = await BackendAPI.get('/product/dashboard')

		if (response.status !== 200) {
			console.error(response)
			return false
		}

		this.data = response.data.data
	},

	mounted() {
	},

	computed: {
		productData() {
			return productsState.data.filter((item) => item.brand_id === brandsState.currentId)
		},
		modalOpen() { return modalState.open },
		types() {
			const response: ProductFileType[] = []

			if (this.contextMenuItem === null) {
				return response;
			}

			for (let availableType of availableProductTypes) {
				// @ts-ignore: Object is possibly 'null'
				if (this.contextMenuItem[availableType.key.toString()]) {
					response.push(availableType)
				}
			}

			return response
		},
	},
	methods: {
		$t,
		openModal(item) {
			setCurrentId(item.brand_id)
			router.push('/' + item.type + '/detail/' + item.id)
		},

		typeLabel(item):string { return $t('type.' + item.type) },

		selectAll () {
			this.productIds = []

			if (! this.allSelected) {
				// this.productlist.forEach(item => {
				//     // this.productIds.push(item.id)
				// });
			}
		},
		choice () {
			this.choiceVisible = !this.choiceVisible
			if ( !this.choiceVisible ) {
				this.allSelected = false
				this.productIds = []
			}
		},
		panelShow () {
			this.panelVisible = true
		},
		cancelPanel () {
			this.panelVisible = false
			this.allSelected = false
			this.productIds = []
		},
		download () {
			console.log('download')
		},
		openContextMenu(e, item, index) {
			this.contextMenuItem = item
			this.contextMenuIndex = index
			const menu: any = this.$refs.menu;
			menu.openMenu(e)
		},
		openView (e) {
			e.preventDefault()
			this.showContextMenuView = !this.showContextMenuView
		},
		openDeletePopup(id) {
			this.deleteId = id
		},
		deleteProduct () {
			if (removeProduct(this.deleteId)) {
				this.deleteId = null
			} else {
				alert('Nepodařilo se smazat produkt.')
			}
		},
		async downloadAll(id) {
			this.downloading = 'all'+id;
			await downloadAll(id)
			this.downloading = '';
		},

		async downloadFileType(id, type: string) {
			this.downloading = type+id;
			await downloadFileType(id, type);
			this.downloading = '';
		},



	}
})
</script>
