import {appLoading, setAppData, state} from "@/store/app";
import { setErrorMessage } from "@/store/app/mutations";
import { loadBrandData } from "@/store/brands";
import { loadTagData } from "@/store/tags";
import { loadRoleData } from "@/store/roles";
import {loadProductData, replaceProductData} from "@/store/products";
import BackendAPI from "@/api/BackendAPI";


export function showErrorMessage(error) {
	setErrorMessage(error)
}

export async function loadAppData() {
	try {
		const response = await BackendAPI.get('/app/data')

		if (response.status !== 200) {
			console.error(response)
			return false
		}

		setAppData(response.data)

	} catch (e) {

	}
}

export async function preload() {
	appLoading(true)
	const preload = [
		loadAppData(),
		loadProductData(),
		loadBrandData(),
		loadTagData(),
		loadRoleData(),
	]
	//preload[] = brandState.load
	await Promise.all(preload)

	appLoading(false)
}
