<template>
	<div class="select-single">
		<label v-if="showLabel" class="typo__label" hidden>{{ label }}</label>
		<Multiselect
			:disabled="disabled"
			v-model="localValue"
			:options="options"
			:placeholder="placeholder"
		/>
	</div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { $t } from '@/config'

export default defineComponent({
	name: 'SingleSelect',

	emits: ['update:modelValue'],

	computed: {
		localValue: {
			get(): any { return this.modelValue; },
			set(value: any) { return this.$emit('update:modelValue', value); }
		}
	},

	props: {
		showLabel: {
			default: true
		},
		label: {
			default: $t('single_select.default_label')
		},
		placeholder: {
			default: $t('single_select.default_placeholder')
		},
		options: {
			default: [],
		},
		modelValue: {
			required: true,
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false,
		}
	},
})

</script>
