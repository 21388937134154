<template>
    <modal class="modal-mask--fixed modal-mask--z-index modal-mask--white modal-hide" :open="modalOpen" @close="close">
		<div class="modal modal--search">
			<button class="modal-close-button" @click="close"><i class="icon icon--cross-black">{{$t('modal.close')}}</i></button>
			<div class="modal-container">
				<div class="modal-body">
					<div class="input-group">
						<div class="form__item form__item--radio">
							<input type="radio" name="category" checked>
							<label for="categoryId">
								<i class="icon icon--category icon--files"></i>
								{{$t('modal.files')}}
							</label>
						</div>
						<div class="form__item form__item--radio">
							<input type="radio" name="category">
							<label for="categoryId">
								<i class="icon icon--category icon--tags"></i>
								{{$t('modal.tags')}}
							</label>
						</div>
						<div class="form__item form__item--radio">
							<input type="radio" name="category">
							<label for="categoryId">
								<i class="icon icon--category icon--users"></i>
								{{$t('modal.users')}}
							</label>
						</div>
					</div>
					<div class="form__item form__item--search flex align-items--center">
						<label for="searchNavMobile" hidden>{{$t('modal.search')}}</label>
						<input id="searchNavMobile" type="text" autocomplete="off" :placeholder="$t('modal.search_placeholder')">
						<button type="submit"></button>
					</div>
				</div>
			</div>
		</div>
    </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Modal from '@/components/Modal/Modal.vue'

export default defineComponent({
	name: 'SearchModal',
	components: { Modal },
	data: () => ({
		modalOpen: false
	}),
	methods: {
		open () {
			this.modalOpen = true
		},
		close () {
			this.modalOpen = false
		}
	}
})
</script>
