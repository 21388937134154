
import { defineComponent } from 'vue'
import SearchBarItem from '@/components/Nav/SearchBarItem.vue';
import {persistSearchTerm, pushSearchTerm, setSearchTags, setSearchTerm, state as appState} from "@/store/app";
import {getFilteredTags} from '@/store/tags';
import {state as roleState} from '@/store/roles';
import router from "@/router";
import {$t, getLanguage} from "@/config";

export default defineComponent({
    name: 'searchBar',
    components: { SearchBarItem },
    data () {
        return {
            show: false,
            //inputText: '',
            showSearch: false,
        }
    },

	computed: {
		getLabelProp(): string { return 'name_' + appState.language },
		tags: {
			get(): any { return appState.searchTags; },
			set(value: any) { setSearchTags(value); }
		},

		areTagsEnabled(): boolean {
			return this.activeItem.route !== '/tag' && this.activeItem.route !== '/user'
		},
		areRolesEnabled(): boolean {
			return this.activeItem.route === '/user'
		},

		getFilteredRoles() {
			return roleState.data
		},

		placeholder() { return appState.persistentSearch=="" ? $t('tag_select.searchbar_placeholder') : appState.persistentSearch },

		getFilteredTags() { return getFilteredTags.value },
    	activeItem() {
    		return appState.searchbarItems.find((item) => item.route === router.currentRoute.value.path) || appState.searchbarItems[0]
		},
		searchbarItems() { return appState.searchbarItems },
		inputText: {
			get(): string { return appState.search; },
			set(value: string) { setSearchTerm(value); }
		}
	},
	methods: {
		clearSelect() {
			//@ts-ignore
			this.$refs.selektor.search = ''
		},
		fultextSearch(value) {
			setSearchTerm(value)
		},

		redirectFromDashboard() {
			if (router.currentRoute.value.path === '/dashboard') {
				router.push('/product')
			}
		},

		persistSearch() {
			persistSearchTerm()
			this.$nextTick(() => {
				this._persistSearchCallback()
			});

		},

		_persistSearchCallback() {
			pushSearchTerm();
		},

		showDropDown () {
			if (this.showSearch) {
				this.show = false
			} else {
				this.show = !this.show
			}
		},
		hideDropDown () {
			this.show = false
		},
		handleInput () {
			if (this.inputText) {
				//this.showSearch = true
				this.show = false
			} else {
				//this.showSearch = false
			}
		},
		handleInputFocus () {
			this.showSearch = false
			//this.inputText = ''
		},
		gotoRoute(route) {
			this.show = false
			router.push(route)
		}
	}
})
