<template>
	<div class="image-list">
		<div class="image-list__header">
			<h3>{{$t('image_list.variants_for_download')}}</h3>
		</div>
		<div class="image-list__container">
			<div class="col">
				<ul class="list">
					<li class="h">PNG</li>
					<li>
						<div>
							<strong>Instagram 1080x1350</strong>
						</div>
					 	<div>
							<a @click="$emit('download-type', 'instagram_portrait_png_rgb')" class="label label--image-list">RGB</a>
							<a @click="$emit('download-type', 'instagram_portrait_png_cmyk')" class="label label--image-list">CMYK</a>
						</div>
					</li>
					<li>
						<div>
							<strong>Instagram 1080x566</strong>
						</div>
						<div>
							<a @click="$emit('download-type', 'instagram_landscape_png_rgb')" class="label label--image-list">RGB</a>
							<a @click="$emit('download-type', 'instagram_landscape_png_cmyk')" class="label label--image-list">CMYK</a>
						</div>
					</li>
					<li>
						<div>
							<strong>Instagram 1080x1080</strong>
						</div>
						<div>
							<a @click="$emit('download-type', 'instagram_square_png_rgb')" class="label label--image-list">RGB</a>
							<a @click="$emit('download-type', 'instagram_square_png_cmyk')" class="label label--image-list">CMYK</a>
						</div>
					</li>
					<li>
						<div>
							<strong>FB post 1200x630</strong>
						</div>
						<div>
							<a @click="$emit('download-type', 'facebook_post_png_rgb')" class="label label--image-list">RGB</a>
							<a @click="$emit('download-type', 'facebook_post_png_cmyk')" class="label label--image-list">CMYK</a>
						</div>
					</li>
				</ul>
			</div>
			<div class="col">
				<ul class="list">
					<li class="h">JPG</li>
					<li>
						<div>
							<strong>Instagram 1080x1350</strong>
						</div>
						<div>
							<a @click="$emit('download-type', 'instagram_portrait_jpg_rgb')" class="label label--image-list">RGB</a>
							<a @click="$emit('download-type', 'instagram_portrait_jpg_cmyk')" class="label label--image-list">CMYK</a>
						</div>
					</li>
					<li>
						<div>
							<strong>Instagram 1080x566</strong>
						</div>
						<div>
							<a @click="$emit('download-type', 'instagram_landscape_jpg_rgb')" class="label label--image-list">RGB</a>
							<a @click="$emit('download-type', 'instagram_landscape_jpg_cmyk')" class="label label--image-list">CMYK</a>
						</div>
					</li>
					<li>
						<div>
							<strong>Instagram 1080x1080</strong>
						</div>
						<div>
							<a @click="$emit('download-type', 'instagram_square_jpg_rgb')" class="label label--image-list">RGB</a>
							<a @click="$emit('download-type', 'instagram_square_jpg_cmyk')" class="label label--image-list">CMYK</a>
						</div>
					</li>
					<li>
						<div>
							<strong>FB post 1200x630</strong>
						</div>
						<div>
							<a @click="$emit('download-type', 'facebook_post_jpg_rgb')" class="label label--image-list">RGB</a>
							<a @click="$emit('download-type', 'facebook_post_jpg_cmyk')" class="label label--image-list">CMYK</a>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="image-list__footer">
			<a @click="$emit('download-all')" href="#" class="btn-simple btn-simple--with-icon">
				<span class="btn-simple__text btn-simple__text--museo-700 btn-simple__text--size-12 btn-simple__text--color-red btn-simple__text--underline">{{$t('image_list.download_all')}}</span>
				<span v-if="downloading==='all'" class="spinning-loader"></span>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	emits: ['download-type', 'download-all'],
	name: 'ImageList',
	props: ['modalItem', 'downloading']
}
</script>

<style>

</style>
