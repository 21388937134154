import { mutation } from './store';

export const setErrorMessage = mutation<string>('app-set-error-message', (state, payload) => {
	state.error = payload
})

export const setAppData = mutation<object>('set-app-data', (state, payload) => {
	state.appData = payload
})

export const appLoading = mutation<boolean>('app-loading', (state, payload) => {
	state.loading = payload
})

export const clearErrorMessage = mutation('app-clear-error-message', (state) => {
	state.error = null
})

export const setSearchTerm = mutation<string>('app-set-search-term', (state, payload) => {
	state.search = payload
})

export const persistSearchTerm = mutation('app-persist-search-term', (state) => {
	state.persistentSearch = state.search
})

export const pushSearchTerm = mutation('app-push-search-term', (state) => {
	state.search = state.persistentSearch
})

export const setSearchTags = mutation<number[]>('app-set-search-tags', (state, payload) => {
	state.searchTags = payload
})

export const setActiveSearchItem = mutation<any>('app-set-active-search-item', (state, payload) => {
	state.search = payload
})

export const setLanguage = mutation<string>('app-set-language', (state, language) => {
	localStorage.setItem('language', language)
	state.language = language
})
