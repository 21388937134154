import { state, getter } from './store';
import { isAdmin, state as authState } from "@/store/auth";

//export const getCurrentBrand = getter('getCurrentBrand', state => state.data[state.currentId])

export const getCurrentBrand = getter('brands.getCurrentBrand', state => state.data.find((item) => item.id === state.currentId));

export const getFilteredBrands = getter('get-currentbrands', function(state) {
	if (isAdmin.value) {
		return state.data
	}

	return state.data.filter( item => authState.data.brands.includes(item.id || 0))
})
