import {mutation} from './store';
import {BrandData} from "@/types";
import {getFilteredBrands} from "@/store/brands/getters";

export const setCurrentId = mutation<any>('brand-set-current-id', (state, currentId) => {
	state.currentId = currentId
})

export const pushBrandData= mutation<BrandData[]>('brand-push-data', (state, payload) => {
	state.data = payload

	if (state.currentId === 0) {
		setCurrentId(getFilteredBrands.value[0].id)
	}
})
