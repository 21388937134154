

import { defineComponent } from 'vue'
import router from "@/router";
import BackendAPI from "@/api/BackendAPI";
import {setCurrentId} from "@/store/brands";

export default defineComponent({
	name: 'ViewedProducts',
    components: {},
    data () {
		return {
			layout: 'grid',
			choiceVisible: false,
			allSelected: false,
			productIds: [],
			modalItem: null,
			showContextMenuView: false,
			panelVisible: false,
			contextMenuItem: null,
			contextMenuIndex: null,
			downloading: "",
			deleteId: null,
			loading:true,
			data: [],
		}
	},
    async created() {

		const response = await BackendAPI.get('/product/dashboard/product')

		if (response.status !== 200) {
			console.error(response)
			return false
		}

		this.data = response.data.data
	},
    mounted() {},
    methods: {
		openModal(item) {
			setCurrentId(item.brand_id)
			router.push('/' + item.type + '/detail/' + item.id)
		},
    },
})

