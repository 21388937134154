
import { defineComponent } from 'vue'
import Modal from '@/components/Modal/Modal.vue'

export default defineComponent({
	name: 'SearchModal',
	components: { Modal },
	data: () => ({
		modalOpen: false
	}),
	methods: {
		open () {
			this.modalOpen = true
		},
		close () {
			this.modalOpen = false
		}
	}
})
