import jwt from 'jsonwebtoken'
import moment from 'moment'
import {AuthState, RoleData} from "@/types";

function getPreLoginAuth() {
	const token = localStorage.getItem('jwt-token') || ''
	if (token) {
		const currentTime = moment().unix()
		const decoded = jwt.decode(token)
		const isNotExpired = decoded && currentTime < decoded.exp
		const state = {
			user: decoded.user,
			token: token,
			expireIn: decoded.exp - currentTime,
			data: decoded.data
		}
		if (isNotExpired) {
			return state
		}
		return {}
	}
}

const state:AuthState = {
	token: '',
	user: null,
	expireIn: 0,
	data: {
		id: 0,
		email: '',
		username: '',
		title_before: '',
		title_after: '',
		phone: '',
		name: '',
		company_name: '',
		company: '',
		roles: [],
		brands: [],
		permissions: [],
		avatar: '',
	},
	...getPreLoginAuth(),
}


export default state
