
import { defineComponent } from 'vue'
import { state as appState } from "@/store/app";

export default defineComponent({
	name: 'Store',
	computed: {
		appData() { return appState.appData }
	}
})

