<template>
	<div class="table-container">
		<perfect-scrollbar class="ps--100">
			<div class="table table--list">
				<div class="table-header">
					<div class="table-header__col table-header__col--first">
						<div class="from__item form__item--checkbox">
							<input type="checkbox" name="tag_all" id="tag_all" @click="selectAll" v-model="allSelected">
							<span></span>
						</div>
					</div>
					<div class="table-header__col">
						<a href="#"> {{$t('tag_select.title')}} <i class="icon icon--listing icon--short"></i></a>
					</div>
					<div class="table-header__col table-header__col--center">
						<a href="#"> {{$t('tag_select.created')}} <i class="icon icon--listing icon--short"></i></a>
					</div>
					<div class="table-header__col table-header__col--center">
						<a href="#"> {{$t('tag_select.global')}} <i class="icon icon--listing icon--short"></i></a>
					</div>
					<div class="table-header__col table-header__col--center">
						<a href="#"> {{$t('tag_select.usage')}} <i class="icon icon--listing icon--short"></i></a>
					</div>
				</div>
				<div class="table__row" v-for="(item, index) in filteredTags" :key="index" @click="openModal(item)">
					<div class="table__col table__col--first">
						<div class="form__item form__item--checkbox">
							<input type="checkbox" :value="item.id" v-model="tagIds" @click.stop>
							<span></span>
						</div>
					</div>
					<div class="table__col table__col--title"><strong>{{ item.name }}</strong></div>
					<div class="table__col table__col--center">{{ item.created_at_formatted }}</div>
					<div class="table__col table__col--center">{{ item.global ? $t('tag_select.yes') : $t('tag_select.no') }}</div>
					<div class="table__col table__col--center">
						<div class="label-list">
							<ul>
								<li v-for="brand in brands(item.brands)" :key="brand">
									<div class="label__item">{{brand}}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<TagModal />
			</div>
		</perfect-scrollbar>
		<div class="drop-container drop-container--table drop-container--show">
			<div class="table__upload table__upload--right">
				<!-- <router-link to="/tag/add" title="Add user" class="button-upload-square"><span>+</span></router-link> -->
				<router-link to="/tag/add" title="Add tag" class="btn btn--header-upload"><span class="btn__text"><span>+</span> {{$t('tag_select.add_tag')}}</span></router-link>
			</div>
		</div>
	</div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import {state as tagState, getFilteredTags} from '@/store/tags'

import TagModal from '@/views/Tags/TagModal.vue'
import router from "@/router";
import {getFilteredBrands, state as brandsState} from "@/store/brands";
import {state as appState} from "@/store/app"
import {state as productsState} from "@/store/products";

export default defineComponent({
    name: 'TagList',
	components: { TagModal },
    data () {
        return {
            allSelected: false,
            tagIds: [],
			showContextMenuView: false,
			panelVisible: false,
		}
    },
	mounted() {

	},
	watch: {
    	currentType(newVal, oldVal) {},
		tagIds(newVal, oldVal) {
    		if (newVal.length != this.filteredTags.length) {
    			this.allSelected = false
			} else if (newVal.length === this.filteredTags.length) {
				this.allSelected = true
			}
		}
	},
	computed: {
    	filteredTags() {
			let result = getFilteredTags.value

			const searchTerm = appState.search || appState.persistentSearch

			if (searchTerm) {
				result = result.filter((item) =>
					(item.name?.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase().search(searchTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase()) ?? -1) >= 0
				)
			}

    		return result
		},
    	filteredBrands() { return getFilteredBrands },
	},
    methods: {
		selectAll () {
            if (this.allSelected) {
				this.tagIds = [];
			} else {
				// @ts-ignore
				this.filteredTags.forEach(item => {this.tagIds.push(item.id)})
                // this.productlist.forEach(item => {
                //     // this.productIds.push(item.id)
                // });
            }
        },
    	brands(items) {
			return items.map(item => this.brand(item))
		},
    	brand(item) {
    		const brnd = this.filteredBrands.value.find(brand => brand.id === item)
    		return brnd && brnd.label
		},
		openModal (item) {
			router.push({ name: 'TagEdit', params: { id: item.id, modalItem: item }, })
		},
		openView (e) {
			e.preventDefault()
			this.showContextMenuView = !this.showContextMenuView
		},
	}
})

</script>
