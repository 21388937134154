
import { defineComponent } from 'vue';
import {getCurrentBrand, state} from "@/store/brands";
import {backendBaseUrl} from '@/config';

export default defineComponent({
	data() {
		return {
			backendBaseUrl
		}
	},
	computed: {
		currentBrand() { return getCurrentBrand.value }
	},
	methods: {

		openModal() {
			this.$emit("openModal");
		}
	}
})

