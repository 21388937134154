import { state, getter } from './store';
import {state as brandsState} from "@/store/brands";

//export const getCurrentBrand = getter('getCurrentBrand', state => state.data[state.currentId])

export const getCurrentBrandX = state => state.data[state.currentId];

export const getFilteredTags = getter('TAGS_GET_FILTERED_TAGS', (state) => state.data.filter(
	(tag) => tag.global === true || tag.brands && tag.brands.includes(brandsState.currentId)
))
