<template>
	<form class="dash">
		<perfect-scrollbar>
			<div class="wrapper wrapper--dash">
				<div class="flex align-items--center justify-content--space-between">
					<h2 v-if="id < 1">{{$t('tag_select.add_tag')}}</h2>
					<h2 v-else>{{$t('tag_select.edit_tag')}}</h2>
					<a href="/tag" :title="$t('tag_select.discard_changes')" class="flex align-items--center">
						<span class="btn__text btn__text--black p-0-1-0-0">{{$t('tag_select.discard_changes')}}</span>
						<span class="btn-rouned align-items--center justify-content--center"><i class="icon icon--cross icon--cross-size-12">{{$t('tag_select.discard_changes')}}</i></span>
					</a>
				</div>
				<div class="flex m-2-5-0">
					<div class="tag-card">

						<div class="form__row">
							<DefaultInput :label="$t('tag_select.title_cs')" v-model="data.name_cs" />
						</div>

						<div class="form__row">
							<DefaultInput :label="$t('tag_select.title_en')" v-model="data.name_en" />
						</div>

						<div class="form__row">
							<TagSelect :label="$t('tags.brand')" :options="availableBrands" v-model="data.brands" />
						</div>

						<div class="form__row">
							<ChecboxtInput :idCheckBox="'globalTag'" :checkboxLabel="$t('tags.global_tag')" v-model="data.global" class="form__item--checkbox" />
						</div>
					</div>
				</div>
				<div class="form-profile__footer flex flex--wrap justify-content--end align-items--center">
					<button @click.prevent="saveChanges" type="submit" class="btn btn--submit btn--shadow btn--radius-5 btn--red btn--with-icon">
						<span v-if="saving" class="btn__text btn__text--white">{{$t('tag_select.saving_changes')}}</span>
						<span v-if="saving" class="spinning-loader spinning-loader--white"></span>
						<span v-else class="btn__text btn__text--white">{{$t('tag_select.save_changes')}}</span>
					</button>
				</div>
			</div>
		</perfect-scrollbar>
	</form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { loadTagData, saveTagData, addTagData, state as tagState } from "@/store/tags";
import {state as brandsState} from "@/store/brands"
import {state as rolesState} from "@/store/roles"
import {$t} from "@/config"
import {ProductData, ProductFileType, TagData, UserData} from "@/types";

import TagSelect from '@/components/Select/TagSelect.vue'

import DefaultInput from "@/components/Input/DefaultInput.vue";
import ChecboxtInput from "@/components/Input/CheckboxInput.vue";
import router from "@/router";

export default defineComponent({
	name: 'TagAdd',
	components: {DefaultInput, ChecboxtInput, TagSelect },
	props: {
		id: {
			type: Number,
			required: true
		}
	},

	computed: {
		availableBrands() { return brandsState.data },
		availableRoles() { return rolesState.data },
	},

	data(): {
		data: TagData,
		saving: boolean
	} {
		return {
			data: {
				id: null,
				value: null,
				name: "",
				name_cs: "",
				name_en: "",
				label: "",
				global: false,
				brands: [],
			},
			saving: false,
		}
	},

	async mounted() {
		if (this.id > 0) {
			if (tagState.data.length === 0) {
				await loadTagData()
			}

			this.data = Object.assign(this.data, tagState.data.find((item) => item.id === this.id))
		} else {
			this.data = Object.assign(this.data, {
				id: null,
				value: null,
				name: "",
				label: "",
				global: false,
				brands: [],
			})
		}

	},

	methods: {
		$t,
		discardChanges() {
			if (this.id > 0) {
				Object.assign(this.data, {
					id: null,
					value: null,
					name: "",
					label: "",
					global: false,
					brands: [],
				})
			} else {
				Object.assign(this.data, this.data = Object.assign(this.data, tagState.data.find((item) => item.id === this.id)))
			}

		},
		async saveChanges() {
			this.saving = true
			const success = await saveTagData(this.data)
			if (success) {
				router.push('/tag')
			} else {
				alert('error')
			}
		},
	}

})
</script>
