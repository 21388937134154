import { mutation } from './store';
import {UserData, UpdateData} from "@/types";

export const pushUserData= mutation<UserData[]>('users-push-data', (state, payload) => {
	state.data = payload
	// for (let item of payload) {
	// 	state.data.push(item)
	// }
})

export const updateItem = mutation<any>('users-update-item', (state,{index, data}) => {
	state.data[index] = data
})
export const addItem = mutation<any>('users-add-item', (state,{data}) => {
	state.data.push(data)
})

export const deleteItem = mutation<any>('users-delete-item', (state,id) => {
	const itemId = state.data.findIndex((item) => item.id === id)
	state.data.splice(itemId, 1)
})
