<template>
	<div class="modal-mask" @click.self="close" v-if="open">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'Modal',
	props: ['open'],
	mounted () {
        document.addEventListener("keydown", (e) => {
            if (this.open && e.keyCode == 27) {
                this.close()
            }
        })
    },
    methods: {
        close () {
            this.$emit('close')
        }
    }
}
</script>
