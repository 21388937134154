<template>
	<modal :open="open" @close="close" class="modal-mask--center">
		<div class="modal modal--popup">
			<button @click="close" class="modal-close-button"><i class="icon icon--cross"> {{$t('popup.close')}}</i></button>
			<div class="modal-container">
				<div class="modal-header">
					<i class="icon icon--success"></i>
					<h3><slot name="heading">{{$t('popup.success')}}</slot></h3>
				</div>
				<div class="modal-body">
					<slot name="default">
						<p>{{$t('popup.it_worked')}}</p>
					</slot>
				</div>
				<div class="modal-footer">
					<button @click="close" class="btn btn--red btn--shadow btn--radius-5 btn--width-180 btn--popup"><strong class="btn__text btn__text--white">{{$t('popup.close')}}</strong></button>
				</div>
			</div>
		</div>
    </modal>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';

export default {
	name: 'SuccessPopup',
	emits: ['closeModal'],
	props: {
		open: {
			type: Boolean,
			default: false
		},
	},
	components: { Modal },
	data () {
		return {
			modalOpen: false
		}
	},
	methods: {
		close() {
			this.$emit('closeModal')
			this.modalOpen = false;
		},
	}
}
</script>
