import {mutation} from './store';
import {TagData} from "@/types";

export const pushTagData= mutation<TagData[]>('tags-push-data', (state, payload) => {
	state.data = payload
})

export const addTagItem = mutation<any>('tags-add-item', (state,{data}) => {
	state.data.push(data)
})
