import { mutation } from './store';
import {ProductData, UpdateData} from "@/types";
import {state as modalState} from "@/store/modal";

export const pushProductData= mutation<ProductData[]>('products-push-data', (state, payload) => {
	for (let item of payload) {
		state.data.push(item)
	}
})

export const replaceProductData= mutation<ProductData[]>('products-replace-data', (state, payload) => {
	state.data = payload
})

export const replaceSingleProductData= mutation<ProductData>('products-replace-single-data', (state, payload) => {
	const index = state.data.find(function(value, index, arr){
		return value.id === payload.id;
	});

	if (index) {
		Object.assign(index, payload)
	}
})

export const updateItem = mutation<any>('products-update-item', (state,{index, data}) => {
	state.data[index] = data
})

export const removeItem = mutation<any>('products-remove-item', (state,{id}) => {
	state.data = state.data.filter(function(value, index, arr){
		return value.id !== id;
	});
})
