<template>
	<button v-if="isAdmin" class="btn btn--header-upload" :title="$t('products.upload_files')" @click="goToUpload">
		<span class="btn__text">
			<span>+</span> <strong>{{$t('products.upload_files')}}</strong> <span>({{uploadQueueLength}})</span>
		</span>
	</button>
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent } from 'vue'
import {getUploadQueueLength} from "@/store/upload";
import { isAdmin } from "@/store/auth";

export default defineComponent({
	name: 'UploadBar',
	emits: ['upload'],
	computed: {
		isAdmin() { return isAdmin.value },
		uploadQueueLength(): number {return getUploadQueueLength.value},
	},
	methods: {
		goToUpload() {
			router.push('/product/upload')
		}
	}
})
</script>
