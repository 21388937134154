
import { defineComponent } from 'vue'
import {$t} from "@/config"

export default defineComponent({
	name: 'DefaultInput',
	emits: ['input', 'update:modelValue'],

	computed: {
		localValue: {
			get(): string { return this.modelValue; },
			set(value: string) { return this.$emit('update:modelValue', value); }
		}
	},

	props: {
		type: {
			type: String,
			default: "text"
		},
		disabled: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: $t('input.default_placeholder'),
		},
		modelValue: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			default: '',
		},

	},
})
