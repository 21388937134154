
import { defineComponent } from 'vue'

import { addData } from "@/store/users";
import { state as brandsState} from "@/store/brands"
import { state as rolesState } from "@/store/roles"
import { UserData } from "@/types";
import {$t} from "@/config";
import TagSelect from '@/components/Select/TagSelect.vue'
import {useToast} from "vue-toastification";
import router from "@/router";
import {getImagePreview} from '@/utils/utils';

const toast = useToast()

export default defineComponent({
	name: 'UserAdd',
	components: { TagSelect },
	props: {
		id: {
			type: Number,
			required: true
		}
	},

	computed: {
		availableBrands() { return brandsState.data },
		availableRoles() { return rolesState.data },
	},

	data(): {
		data: UserData,
		avatar: string
	} {
		return {
			data: {
				id: null,
				avatar: "",
				name: "",
				surname: "",
				company_name: "",
				email: "",
				phone: "",
				position: "",
				title_before: "",
				title_after: "",
				roles: [],
				brands: [],
				oldPassword: '',
				newPassword: '',
				newPassword2: '',
			},
			avatar: '',
		}
	},

	async mounted() {
		this.data = Object.assign(this.data, {
			id: null,
			avatar: "",
			name: "",
			surname: "",
			company_name: "",
			email: "",
			phone: "",
			position: "",
			title_before: "",
			title_after: "",
			roles: [],
			brands: [],
			oldPassword: '',
			newPassword: '',
			newPassword2: '',
		})
	},

	methods: {
		$t,
		getImagePreview,
		discardChanges() {
			Object.assign(this.data, {
				id: null,
				avatar: "",
				name: "",
				surname: "",
				company_name: "",
				email: "",
				phone: "",
				position: "",
				title_before: "",
				title_after: "",
				roles: [],
				brands: [],
				oldPassword: '',
				password: '',
				passwordAgain: '',
			})
			this.avatar = ''
		},

		setImage(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (! files.length) {
				return
			}
			this.avatar = files[0]
		},

		async saveChanges() {


			if (this.data.email.length < 1) {
				toast.error($t('user.error_email'))
				return false
			}
			if (this.data.name.length < 1) {
				toast.error($t('user.error_name'))
				return false
			}
			if (this.data.surname.length < 1) {
				toast.error($t('user.error_surname'))
				return false
			}

			if (this.data.roles.length < 1) {
				toast.error($t('user.error_roles'))
				return false
			}

			console.log(this.avatar)
			if (await addData(this.data, this.avatar)) {
				router.push('/user')
			}
		},

		cancel() {
			router.push('/user')
		},
	}

})
