
import { defineComponent } from 'vue'
import { uploadAddFileToQueue } from "@/store/upload";

export default defineComponent({
	name: 'UploadDropTarget',
	emits: ['upload'],
	methods: {
		addFile(e) {
			let droppedFiles = e.type=="change" ? e.target.files : e.dataTransfer.files;
			console.log(droppedFiles);
			if(!droppedFiles) {
				return
			}
			([...droppedFiles]).forEach(f => {
				uploadAddFileToQueue(f)
			});
		},
	}
})
