import { mutation } from './store';
import BackendAPI from "@/api/BackendAPI";

export const clearAuth = mutation('clear-auth', (state, payload) => {
	state.user = null
	state.token = ''
	state.expireIn = 0
})

export const setAuth = mutation<any>('set-auth', (state, { token, user, expireIn, data }) => {
	state.user = user
	state.token = token
	state.expireIn = expireIn
	state.data = data
	BackendAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token
})
