<template>
    <div class="form__item form__item--radio">
        <input type="radio" :id="'categoryId' + [searchbaritem.label]" name="category" :value="searchbaritem.category" :change="changeSelect">
        <label :for="'categoryId' + [searchbaritem.label]">
            <i :class="'icon icon--category' + ' ' + 'icon' + [searchbaritem.icon_class]" v-html="searchbaritem.icon"></i>
            {{ $t(searchbaritem.label) }}
        </label>
    </div>
</template>

<script>
export default {
    name: 'SearchBarItem',
    props: ['searchbaritem'],
    methods: {
        changeSelect () {

        }
    }
}
</script>
