import { BrandData } from "@/types";
import { backendBaseUrl } from '@/config'

interface State {
	currentId: number,
	data: BrandData[]
}

const state: State = {
	currentId: 0,
	data: []
}

export default state
