import {backendBaseUrl} from "@/config";

export const arrayToObject = (arr, keyField) => {
  return Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })))
}

export const typeOf = obj => {
  return {}.toString
    .call(obj)
    .split(' ')[1]
    .slice(0, -1)
    .toLowerCase()
}

export const getImagePreview = function(file) {
	if ( file.type.indexOf('image')!==-1 ) return URL.createObjectURL(file);
	return `${backendBaseUrl}files/image_not_found.png`;
};
