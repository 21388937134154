<template>
	<div class="aside">
		<perfect-scrollbar>
			<aside>
				<BrandButton @click="openModal"/>
				<nav class="nav" role="navigation">
					<ul>
						<li><router-link to="/product" class="nav__item" :title="$t('nav.products')">{{$t('nav.products')}}</router-link></li>
						<li><router-link to="/product_sk" class="nav__item" title="$t('nav.products_sk')">{{$t('nav.products_sk')}}</router-link></li>
						<li><router-link to="/product_hu" class="nav__item" title="$t('nav.products_hu')">{{$t('nav.products_hu')}}</router-link></li>
						<li v-if="isAdmin"><router-link to="/product/archive" class="nav__item" title="$t('nav.archive')">{{$t('nav.archive')}}</router-link></li>
						<li v-if="isAdmin"><router-link to="/video" class="nav__item" title="$t('nav.videos')">{{$t('nav.videos')}}</router-link></li>
						<li v-if="isAdmin"><router-link to="/recipe" class="nav__item" title="$t('nav.recipes')">{{$t('nav.recipes')}}</router-link></li>
						<li v-if="isAdmin"><router-link to="/photo" class="nav__item" title="$t('nav.photos')">{{$t('nav.photos')}}</router-link></li>
					</ul>
				</nav>
				<div class="row row--1">
					<UploadBar/>
				</div>
				<div class="row row--2">
					<UserBarMobile/>
				</div>
				<div class="row row--3">
					<LangBar/>
				</div>
			</aside>
		</perfect-scrollbar>
	</div>
</template>

<script>
import UserBarMobile from '@/components/Nav/UserBarMobile.vue';
import LangBar from '@/components/Nav/LangBar.vue';
import UploadBar from '@/components/Nav/UploadBar.vue'
import BrandButton from '@/components/Nav/BrandButton.vue';
import {getFilteredTags} from "@/store/tags";
import {isAdmin} from "@/store/auth";

export default {
    name: 'SidebarNav',
    components: {
        UserBarMobile, LangBar, BrandButton, UploadBar
    },
	computed: {
		isAdmin() { return isAdmin.value },
	},
	emits: ['open'],
	methods: {
		openModal () {
			this.$emit('open')
		}
	}
}
</script>
