<template>
    <div class="lang-bar">
        <div class="dropdown">
            <button v-click-away="hideDropDown" @click="toggleDropDown">
                <span class="dropdown__flag">
                    <img v-if="language=='en'" src="@/assets/svg/flags/en_icon@2x.jpg">
                    <img v-if="language=='cs'" src="@/assets/svg/flags/cz_icon@2x.jpg">
                </span>
                <i class="icon icon--arrow icon--arrow-down"></i>
            </button>
            <transition name="fade">
                <div class="dropdown__menu" v-if="show">
                    <button @click.prevent="setLanguage('en')"><img src="@/assets/svg/flags/en_icon@2x.jpg"> <strong>ENGLISH</strong></button>
					<button @click.prevent="setLanguage('cs')"><img src="@/assets/svg/flags/cz_icon@2x.jpg"> <strong>ČESKY</strong></button>
                </div>
            </transition>
        </div>
	</div>
</template>

<script lang="ts">

import { defineComponent } from "vue"
import {state as appState, setLanguage} from "@/store/app"

export default defineComponent({
    name: 'LangBar',
	data () {
		return {
			show: false,
		}
	},
    computed: {
		language() { return appState.language; }
	},
	methods: {
        setLanguage(lang) {
			setLanguage(lang)
		},
		toggleDropDown () {
			this.show = !this.show
		},
		hideDropDown () {
			this.show = false
		},
	}
})
</script>

