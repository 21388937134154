

import { defineComponent } from "vue"
import { logout, userData } from "@/store/auth"
import {isAdmin} from "@/store/auth";

export default defineComponent({
    name: 'UserBar',
	data () {
		return {
			show: false,
			showSettings: false,
		}
	},
	computed: {
    	userData() { return userData.value },
		isAdmin() { return isAdmin.value },
	},
	methods: {
		toggleDropDown () {
			this.show = !this.show
		},
		hideDropDown () {
			this.show = false
		},

		toggleSettingsDropDown () {
			this.showSettings = !this.showSettings
		},
		hideSettingsDropDown () {
			this.showSettings = false
		},

		handleLogout() {
			logout()
		}
	}
})
