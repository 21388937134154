<template>
    <header class="flex">
        <div class="col col--1">
			<button @click="openSearchModal">
				<i class="icon icon--search-black"></i>
			</button>
			<router-link to="/dashboard" title="Dashboard">
				<img src="@/assets/svg/tools/logo.svg" alt="Orkla databanka">
			</router-link>
        </div>
        <div class="col col--2">
			<router-link to="/dashboard" title="Dashboard">
				<img src="@/assets/svg/tools/logo.svg" alt="Orkla databanka">
			</router-link>
            <SearchBar />
        </div>
        <div class="col col--3">
            <router-link to="/dashboard" class="btn-rouned flex justify-content--center align-items--center" title="Dashboard">
                <i class="icon icon--home"></i>
            </router-link>
			<UploadDropTarget>
				<UploadBar/>
			</UploadDropTarget>
            <button id="burger" class="btn-rouned flex justify-content--center align-items--center" title="Navigace" :class="{'active': active}" @click="toggleSidebar">
				<i class="burger"><span></span><span></span><span></span></i>
			</button>
            <UserBar/>
			<LangBar/>
        </div>
    </header>
</template>

<script>
import SearchBar from '@/components/Nav/SearchBar.vue'
import UserBar from '@/components/Nav/UserBar.vue'
import LangBar from '@/components/Nav/LangBar.vue'
import UploadBar from '@/components/Nav/UploadBar.vue'
import UploadDropTarget from '@/components/Upload/UploadDropTarget.vue'
import { useToast } from "vue-toastification";

export default {
    name: 'HeaderNav',
    components: {
        SearchBar, UserBar, LangBar, UploadBar, UploadDropTarget
    },
	emits: ['open','toggleSidebar'],
    data () {
        return {
			active: false,
			toast: null,

        }
    },
	created() {
		this.toast = useToast()
	},
	methods: {
		openSearchModal () {
			this.$emit('open');
		},
		toggleSidebar () {
			this.active = !this.active
			this.$emit('toggleSidebar')
		},
	}
}
</script>
