<template>
	<div class="drop-container"
		@drop.prevent="addFile"
		@dragover.prevent
	>
		<slot></slot>

		<input type="file" @change="addFile" multiple>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { uploadAddFileToQueue } from "@/store/upload";

export default defineComponent({
	name: 'UploadDropTarget',
	emits: ['upload'],
	methods: {
		addFile(e) {
			let droppedFiles = e.type=="change" ? e.target.files : e.dataTransfer.files;
			console.log(droppedFiles);
			if(!droppedFiles) {
				return
			}
			([...droppedFiles]).forEach(f => {
				uploadAddFileToQueue(f)
			});
		},
	}
})
</script>
