import { ModalType } from "@/enums"

interface State {
	open: boolean;
	activeType: ModalType|null;
	data: Data;
	id: number|null;
	dataIndex: number;
}

interface Data {
	id: number|null;
	name: String|null;
}

const state: State = {
	open: false,
	activeType: null,
	id: null,
	dataIndex: 0,
	data: {
		id: null,
		name: null,
	},
}

export default state
