
import router from "@/router";
import { defineComponent } from 'vue'
import {getUploadQueueLength} from "@/store/upload";
import { isAdmin } from "@/store/auth";

export default defineComponent({
	name: 'UploadBar',
	emits: ['upload'],
	computed: {
		isAdmin() { return isAdmin.value },
		uploadQueueLength(): number {return getUploadQueueLength.value},
	},
	methods: {
		goToUpload() {
			router.push('/product/upload')
		}
	}
})
