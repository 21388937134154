<template>
    <modal class="modal-mask--center modal-mask--z-index" :open="modalOpen" @close="close">
		<perfect-scrollbar class="ps--100">
			<div class="modal modal--brand" @click.stop>
				<button class="modal-close-button" @click="close"><i class="icon icon--cross">{{$t('modal.close')}}</i></button>
				<div class="modal-container">
					<div class="modal-header">
						<h3>{{$t('modal.select_brand')}}</h3>
					</div>
					<div class="modal-body">
						<div class="brand-grid">
							<div
								v-for="item in brands" :key="item.id"
								class="form__item form__item--brand"
								@click="setId(item.id)"
							>
								<label>
									<input type="radio" name="brand">
									<img :src="backendBaseUrl + item.image" :alt="item.title">
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</perfect-scrollbar>
    </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {state, setCurrentId, getFilteredBrands} from "@/store/brands";
import {state as authState} from "@/store/auth";
import Modal from '@/components/Modal/Modal.vue';
import {backendBaseUrl} from '@/config';
import {isAdmin} from "@/store/auth";
import router from "@/router";


export default defineComponent({
	name: 'BrandModal',
	components: { Modal },
	data () {
		return {
			modalOpen: false,
			backendBaseUrl
		}
	},
	computed: {
		brands() {
			return getFilteredBrands.value
		}
	},

	methods: {
		setId(id) {
			setCurrentId(id)
			this.close()
			if (router.currentRoute.value.path === '/dashboard' || router.currentRoute.value.path === '/user' || router.currentRoute.value.path === '/tag') {
			 	router.push('/product')
			}
		},
		open () {
			this.modalOpen = true
		},
		close () {
			this.modalOpen = false
		}
	}
})
</script>
