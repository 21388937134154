
import { defineComponent } from 'vue';
import {state, setCurrentId, getFilteredBrands} from "@/store/brands";
import {backendBaseUrl} from '@/config';
import router from "@/router";

export default defineComponent({
	name: 'SelectBrand',
	data () {
		return {
			backendBaseUrl
		}
	},
	computed: {
		brands() {
			let obj = getFilteredBrands.value;
			let result = {};
			for (let k in obj) {
				if (!obj[k].color) continue;
				result[k] = obj[k];
			}
			return result;
			// return getFilteredBrands.value
		}
	},
	emits: ['open'],
	methods: {
		setId(id) {
			setCurrentId(id)
			if (router.currentRoute.value.path === '/dashboard' || router.currentRoute.value.path === '/user' || router.currentRoute.value.path === '/tag') {
			 	router.push('/product')
			}
		},
		openModal () {
			this.$emit('open')
		}
	}
})
