<template>
	<div class="drop-container"
		@drop.prevent="addFile"
		@dragover.prevent
	>
		<slot v-if="!uploading" name="default"></slot>
		<slot v-else name="uploading"></slot>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { uploadProductChanges } from "@/store/products";
import { ProductData } from '@/types/index'

export default defineComponent({
	name: 'UploadProductChangeDropTarget',
	props: {
		item: {
			type: Object,
			required: true,
		}
	},

	data() {
		return {
			uploading: false,
		}
	},

	methods: {
		async addFile(e) {
			let droppedFiles = e.dataTransfer.files
			if(!droppedFiles) {
				return
			}
			this.uploading = true;
			await uploadProductChanges(this.item.id, droppedFiles);

			this.uploading = false;
		},
	}
})
</script>
