import {IndexIdPair, ModalPayload, ProductData} from '@/types'
import { ModalType } from '@/enums'
import {getter, mutation} from './store'
import {WriteState} from "@harlem/core";
import {loadProductDetail, replaceSingleProductData} from "@/store/products";
import { ref } from "vue";

export const openModal = mutation<ModalPayload>('modal-open-modal', (state, modal) => {
console.log('open modal')
	state.open = true
	state.activeType = modal.type
	state.data = modal.data
})

export const closeModal = mutation('modal-close-modal', (state) => {
	state.open = false
	state.activeType = null
	state.id = null
})

export const updateCurrentData = mutation<ProductData>('modal-update-current-data', (state, data) => {
	state.data = data
})

export const openProductModal = mutation<ProductData>('modal-open-product-modal', (state, item) => {
	if (item.id) {
		loadProductDetail(item.id)
	}

	openModal({
		type: ModalType.ProductDetail,
		data: item
	})
})

export const openVideoModal = mutation<ProductData>('modal-open-video-modal', (state, item) => {
	openModal({
		type: ModalType.VideoDetail,
		data: item
	})
})

export const openRecipeModal = mutation<ProductData>('modal-open-recipe-modal', (state, item) => {
	openModal({
		type: ModalType.RecipeDetail,
		data: item
	})
})

export const openMarketingModal = mutation<ProductData>('modal-open-marketing-modal', (state, item) => {
	openModal({
		type: ModalType.MarketingDetail,
		data: item
	})
})

export const openLegislativeModal = mutation<ProductData>('modal-open-legislative-modal', (state, item) => {
	openModal({
		type: ModalType.MarketingDetail,
		data: item
	})
})

export const openTagModal = mutation<ProductData>('modal-open-tag-modal', (state, item) => {
	openModal({
		type: ModalType.TagDetail,
		data: item
	})
})
