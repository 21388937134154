
import { defineComponent } from 'vue'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import {$t, getLanguage} from '@/config'
import { QuillEditor } from '@vueup/vue-quill'
import Modal from '@/components/Modal/Modal.vue'
import ImageList from '@/components/Products/ImageList.vue'
import { getFilteredTags } from '@/store/tags';
import DefaultInput from '@/components/Input/DefaultInput.vue'
import SingleSelect from '@/components/Select/SingleSelect.vue'
import OverlayPopup from '@/components/Popup/OverlayPopup.vue'
import TagSelect from '@/components/Select/TagSelect.vue'
import UploadProductChangeDropTarget from '@/components/Upload/UploadProductChangeDropTarget.vue'
import { availableProductTypes } from "@/types/product";
import { state as modalState, closeModal } from "@/store/modal"
import { state as appState } from "@/store/app"
import { getFilteredBrands, state as brandState } from "@/store/brands"
import {
	state as productsState,
	saveData,
	removeFileType,
	downloadFileType,
	downloadAll,
	loadProductData, downloadType, state as productState,

} from "@/store/products"

import {ModalType} from '@/enums'
import {ProductData, ProductFileType} from "@/types"
import {isAdmin} from "@/store/auth"
import 'quill-paste-smart';

export default defineComponent({
	name: 'ProductModal',
	props: {
		'type': {
			type: String,
			required: true,
		},
		'modalItem': {
			type: Object,
			required: false,
			default: null,
		},
	},
	components: { Modal, ImageList, DefaultInput, SingleSelect, TagSelect, UploadProductChangeDropTarget, QuillEditor, OverlayPopup },

	data(): {
		itemIndex: number,
		data: ProductData,
		downloading: string,
		saving: boolean,
		activeTab: number,
		ready: boolean,
		videoStatus: boolean,
		videoHover: boolean,
		overlayPopup: boolean,
		quillOptions: any,
		quillOptionsDisabled: any,
	} {
		return {
			data: {
				id: null,
				ids: [],
				type: '',
				brand_id: null,
				sap_id: null,
				name: "",
				ean: "",
				description: "",
				tags: [],
				psd_master_rgb: null,
				psd_master_cmyk: null,
				png_master_rgb: null,
				png_master_cmyk: null,
				jpeg_master_rgb: null,
				jpeg_master_cmyk: null,
				content_legal: "",
				content_marketing: "",
				video_master: null,
				thumbnail: '',
				full_image: '',
				changelog: [],
				deleted: false,
				archived: false,
				frozen: false,
			},
			downloading: '',
			saving: false,
			itemIndex: 0,
			activeTab: 1,
			ready: false,
			videoStatus: false,
			videoHover: false,
			overlayPopup: false,
			quillOptions: {
				modules: {
					clipboard: {
						allowed: {
							tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
							attributes: ['href', 'rel', 'target', 'class']
						},
						keepSelection: true,
						substituteBlockElements: true,
						magicPasteLinks: true,
						hooks: {
							uponSanitizeElement(node, data, config) {
								console.log(node);
							},
						},
					}
				}
			},
			quillOptionsDisabled: {
				modules: {
					"toolbar": false
				}
			}
		}
	},

	computed: {
		getLabelProp() { return 'name_' + appState.language },
		getFilteredTags() { return getFilteredTags },
		originalData() { return modalState.data },
    	modalOpen() { return modalState.activeType === ModalType.ProductDetail },
		types() {
    		const response: ProductFileType[] = []
    		for (let availableType of availableProductTypes) {
				if (this.data[availableType.key.toString()]) {
					response.push(availableType)
				}
			}
    		return response
		},
		brandData() { return getFilteredBrands },
		isAdmin():boolean { return isAdmin.value },
		canEdit():boolean { return ! isAdmin.value },
		textTabs(): string[] {
			return $t('products.tabs')[this.type] ?? $t('products.default_tabs');
		},
		typeLabel(item):string { return $t('type.' + item.type) },
		pairProducts():boolean { return this.type !== 'product' },
		productSelectDatasource() {
			return productState.data.filter(
				(product) => product.brand_id === brandState.currentId && product.archived !== true && (product.sap_id || product.ean)
			)
		},
	},

	mounted () {
		this.updateFromStore()
		this.ready = true
	},
	methods: {
		$t,
		updateFromStore() {
			Object.assign(this.data, modalState.data)
		},
		show () {
			// openProductModal(modalState.data.id)
		},
		close () {
			closeModal()
		},
		discardChanges() {
			Object.assign(this.data, modalState.data)
		},
		async saveChanges() {
			this.saving = true
			await saveData(modalState.data, this.data)
			await loadProductData()
			this.saving = false
		},
		removeFileType(type: string) {
			if (removeFileType(modalState.data, this.data.id, type)) {
				this.data[type] = null
			}
		},
		async downloadFileType(type: string) {
			this.downloading = type;
			await downloadFileType(this.data.id, type)
			this.downloading = '';
		},
		async downloadType(type: string) {
			this.downloading = type;
			await downloadType(this.data.id, type)
			this.downloading = '';
		},
		async downloadAll() {
			this.downloading = 'all';
			await downloadAll(this.data.id)
			this.downloading = '';
		},
		toggleVideo() {
			if (this.videoStatus) {
				/** @ts-ignore */
				this.$refs.videoPlayer.pause();
			} else {
				/** @ts-ignore */
				this.$refs.videoPlayer.play();
			}
			this.videoStatus = !this.videoStatus;
		},
		play() {
			/** @ts-ignore */
			this.$refs.videoPlayer.play();
		},
		pause() {
			/** @ts-ignore */
			this.$refs.videoPlayer.pause();
		}

	}
})
