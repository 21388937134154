<template>
	<modal :open="open" @close="close" class="modal-mask--center">
		<div class="modal modal--popup">
			<button @click="close" class="modal-close-button"><i class="icon icon--cross"> {{$t('popup.close')}}</i></button>
			<div class="modal-container">
				<div class="modal-header">
					<i class="icon icon--warning"></i>
					<h3>{{$t('popup.warning')}}</h3>
				</div>

				<div class="modal-body">
					<slot>
						<p>{{$t('popup.delete_confirmation')}}</p>
					</slot>
				</div>
				<div class="modal-footer">
					<button @click="close" class="btn btn--red btn--shadow btn--radius-5 btn--width-180 btn--popup"><strong class="btn__text btn__text--white">{{$t('popup.cancel')}}</strong></button>
					<button @click="deleteItem" class="btn btn--white btn--shadow btn--radius-5 btn--width-180 btn--with-icon btn--popup"><strong class="btn__text btn__text--black">{{$t('popup.remove')}}</strong> <i class="icon icon--trash-red"></i></button>
				</div>
			</div>
		</div>
    </modal>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';

export default {
	name: 'WarningPopup',
	emits: ['deleteItem', 'closeModal'],
	props: {
		open: {
			type: Boolean,
			default: false
		},
	},
	components: { Modal },
	data () {
		return {
			modalOpen: true
		}
	},
	methods: {
		close() {
			this.$emit('closeModal')
			this.modalOpen = false;
		},
		deleteItem() {
			this.$emit('deleteItem')
		}
	}
}
</script>
