import BackendAPI from "@/api/BackendAPI"
import {state} from "./store"
import {pushProductData, replaceProductData, replaceSingleProductData, updateItem, removeItem} from "@/store/products/mutations";
import {updateCurrentData} from "@/store/modal";
import { $t } from '@/config'
import {useToast} from "vue-toastification";
const toast = useToast()

export async function loadProductData() {
	try {
		const response = await BackendAPI.get('/product/find')

		if (response.status !== 200) {
			toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
			return false
		}

		replaceProductData(response.data.data)

	} catch (e) {

	}
}

export async function loadProductDetail(id: Number) {
	try {
		const response = await BackendAPI.get('/product/find/' + id)

		if (response.status !== 200) {
			console.error(response)
			toast.error($t('modal.failed_to_load_data'))
			return false
		}

		replaceSingleProductData(response.data.data)

	} catch (e) {
	}
}

export async function uploadProductChanges(productId, droppedFiles) {
	await Promise.all(Array.from(droppedFiles).map(async (file) => {
		let upload = await uploadProductChange(productId, file)
	}));
	toast.success($t('modal.change_saved'))
//	([...droppedFiles]).forEach((f) => uploadProductChange(productId, f));
}

export async function uploadProductChange(id, data)
{
	let formData = new FormData()
	formData.append(`file[0]`, data)
	const response = await BackendAPI.post("/upload/upload-product-change/" + id, formData, {
		onUploadProgress: function( progressEvent ) {
			//console.log('Upload progress: ')
			//console.log(progressEvent)
		}
	})

	if (response.status !== 200) {
		toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
		return false
	}
	toast.success($t('modal.change_saved'))
	//updateItem({index, data})
}

export async function saveData(index, data): Promise<boolean>
{
	const response = await BackendAPI.post("/product/update/" + data.id, data)

	if (response.status !== 200) {
		toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
		return false
	}

	updateCurrentData(data)
	toast.success($t('modal.change_saved'))
	return true
}

export async function removeProduct(id): Promise<boolean>
{
	const response = await BackendAPI.delete("/product/remove/" + id)

	if (response.status !== 200) {
		toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
		return false
	}

	removeItem({id})
	toast.success($t('modal.change_saved'))
	return true
}

export async function removeFileType(index, id, type): Promise<boolean>
{
	const response = await BackendAPI.delete("/product/remove-file-type/" + id + '/' + type )

	if (response.status !== 200) {
		toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
		return false
	}

	toast.success($t('modal.change_saved'))
	return true
}

export async function downloadFileType(id, type)
{
	const response = await BackendAPI.get("/product/download-file-type/" + id + '/' + type, {
		responseType: 'arraybuffer',
		onDownloadProgress: function( progressEvent ) {
			console.log('Download progress: ')
			console.log(progressEvent)
		}
	})

	if (response.status !== 200) {
		toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
		return false
	}

	let blob = new Blob([response.data])
	let link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = response.headers['x-suggested-filename'] || 'unknown_filename'
	link.click()

}

export async function downloadType(id, type)
{
	const response = await BackendAPI.get("/product/download-type/" + id + '/' + type, {
		responseType: 'arraybuffer',
		onDownloadProgress: function( progressEvent ) {
			console.log('Download progress: ')
			console.log(progressEvent)
		}
	})

	if (response.status !== 200) {
		toast.error($t('modal.failed_to_load_data') + ` (response.data.message)`)

		return false
	}

	console.log(response.headers)


	let blob = new Blob([response.data])
	let link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = response.headers['x-suggested-filename'] || 'unknown_filename'
	link.click()

}



export async function downloadAll(id,)
{
	const response = await BackendAPI.get("/product/download-all/" + id, {
		responseType: 'arraybuffer',
		onDownloadProgress: function( progressEvent ) {
			console.log('Download progress: ')
			console.log(progressEvent)
		}
	})

	if (response.status !== 200) {
		toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
		return false
	}

	console.log(response.headers)

	let blob = new Blob([response.data])
	let link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = response.headers['x-suggested-filename'] || 'unknown_filename'
	link.click()

}

export async function downloadExport(type, ids)
{
	const response = await BackendAPI.get("/product/download-export/" + type + "/" + ids.join(','), {
		responseType: 'arraybuffer',
		onDownloadProgress: function( progressEvent ) {
			console.log('Download progress: ')
			console.log(progressEvent)
		}
	})

	if (response.status !== 200) {
		toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
		return false
	}

	let blob = new Blob([response.data])
	let link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = response.headers['x-suggested-filename'] || 'unknown_filename'
	link.click()

}

export async function downloadSelection(ids)
{
	const response = await BackendAPI.get("/product/download-selection/" + ids.join(','), {
		responseType: 'arraybuffer',
		onDownloadProgress: function( progressEvent ) {
			console.log('Download progress: ')
			console.log(progressEvent)
		}
	})

	if (response.status !== 200) {
		toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
		return false
	}

	let blob = new Blob([response.data])
	let link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = response.headers['x-suggested-filename'] || 'unknown_filename'
	link.click()

}
