
import {defineComponent} from 'vue'
import {preload, state as appState} from "@/store/app";

export default defineComponent({

	computed: {
		loading() { return appState.loading }
	},

	async created() {
		await preload()
	}
})

