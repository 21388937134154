import BackendAPI from "@/api/BackendAPI"
import {state} from "./store"
import {pushRoleData} from "@/store/roles/mutations";
import { $t } from '@/config'
import {useToast} from "vue-toastification";
const toast = useToast()

export async function loadRoleData() {
	try {
		const response = await BackendAPI.get('/role/find')

		if (response.status !== 200) {
			toast.error($t('modal.failed_to_load_data'))
			return false
		}

		pushRoleData(response.data.data)

		//replaceBrand(response.data.data)
		//http://api.db.orkla.test/files/products/1/products_miniatures_1.png
	} catch (e) {

	}
}
