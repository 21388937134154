
import router from "@/router";
import UserProfileModal from '@/views/Users/UserProfileModal.vue';
import {loadData, state as userState} from '@/store/users'
import {state as brandState} from '@/store/brands'
import {state as roleState} from '@/store/roles'
import {defineComponent} from "vue";
import {state as appState} from "@/store/app"


export default defineComponent({
    name: 'UserList',
    components: { UserProfileModal },

	created() {
		loadData()
	},

	computed: {
		userData() {
			let result = userState.data

			if (appState.searchTags.length > 0) {
				result = result.filter((item) => item.roles && item.roles.some(tag => appState.searchTags.includes(tag)))
			}

			const searchTerm = appState.search || appState.persistentSearch

			if (searchTerm) {
				result = result.filter((item) =>
					(item.name?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
					|| (item.surname?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
					|| (item.email?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
					|| (item.position?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
				)
			}

			return result
		},

	},

    data() {
        return {
			modalItem: null,
        }
    },
	methods: {
    	closeModal() {
    		this.modalItem = null
		},
		openModal (item) {
			this.modalItem = item
			//router.push({ name: 'UserProfile', params: { id: item.id, modalItem: item }, })
		},
		mapCompanies(list) {
			if (!list) {
				return ''
			}
			// @ts-ignore
			return list.map((item) => brandState.data.find((brand) => brand.id === item)?.title || '').join(', ')
		},
		mapRoles(list) {
			if (!list) {
				return ''
			}
			// @ts-ignore
			return list.map((item) => roleState.data.find((role) => role.id === item)).filter((item) => item)
		}
	}
})
