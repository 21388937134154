<template>
	<button v-if="currentBrand" class="brand" @click="openModal">
		<img :src="backendBaseUrl + currentBrand.image" :alt="currentBrand.title">
		<strong>{{ currentBrand.title }}</strong>
		<i class="icon icon--arrow icon--arrow-down"></i>
	</button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {getCurrentBrand, state} from "@/store/brands";
import {backendBaseUrl} from '@/config';

export default defineComponent({
	data() {
		return {
			backendBaseUrl
		}
	},
	computed: {
		currentBrand() { return getCurrentBrand.value }
	},
	methods: {

		openModal() {
			this.$emit("openModal");
		}
	}
})

</script>

