<template>
	<div class="table-container">
		<perfect-scrollbar class="ps--100">
			<div class="table table--list table--users">
				<div class="table-header">
					<div class="table-header__col table-header__col--first"></div>
					<div class="table-header__col">
						<a href="#">{{$t('user.name_surname')}} <i class="icon icon--short"></i></a>
					</div>
					<div class="table-header__col table-header__col--center display-sm-none">
						<a href="#">{{$t('user.last_login')}} <i class="icon icon--short"></i></a>
					</div>
					<div class="table-header__col table-header__col--center display-m-none">
						<a href="#">{{$t('user.registration_date')}} <i class="icon icon--short"></i></a>
					</div>
					<div class="table-header__col display-sm-none">{{$t('user.company')}}</div>
					<div class="table-header__col display-sm-none">{{$t('user.roles')}}</div>
				</div>
					<div class="table__row" :key="item.id" v-for="item in userData" @click="openModal(item)">
						<div class="table__col table__col--first table__col--avatar">
							<span class="img">
								<img v-if="item.avatar" :src="item.avatar" :alt="item.name">
							</span>
						</div>
						<div class="table__col table__col--title display-lg-none">
							<strong>{{ item.name }} {{ item.surname }}</strong>
							<span>{{ item.brands }}</span>
						</div>
						<div class="table__col table__col--title display-sm-none"><strong>{{ item.name }} {{ item.surname }}</strong></div>
						<div class="table__col table__col--center display-sm-none">{{ item.last_login_formatted }}</div>
						<div class="table__col table__col--center display-m-none">{{ item.created_at_formatted }}</div>
						<div class="table__col display-sm-none">{{ mapCompanies(item.brands) }}</div>

						<div class="table__col table__col--center">
							<div class="label-list">
								<ul>
									<li v-for="role in mapRoles(item.roles)" :key="role">
										<div :class="{'label__item__orange': role.tag === 'external'}" class="label__item">{{role?.label || ''}}</div>
									</li>
								</ul>
							</div>
						</div>
					</div>

				<UserProfileModal ref="modal" :open="modalItem !== null" :modalItem="modalItem" @close="closeModal" />

<!--				<UserProfileModal ref="modal" :open="!!modalItem" :modalItem="modalItem"/>-->

				<br /><br /><br /><br />
			</div>

		</perfect-scrollbar>
		<div class="drop-container drop-container--table drop-container--show">
			<div class="table__upload table__upload--right">
				<!-- <router-link to="/user/add" title="Add user" class="button-upload-square"><span>+</span></router-link> -->
				<router-link to="/user/add" title="Add user" class="btn btn--header-upload"><span class="btn__text"><span>+</span> {{$t('user.add_user')}}</span></router-link>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import router from "@/router";
import UserProfileModal from '@/views/Users/UserProfileModal.vue';
import {loadData, state as userState} from '@/store/users'
import {state as brandState} from '@/store/brands'
import {state as roleState} from '@/store/roles'
import {defineComponent} from "vue";
import {state as appState} from "@/store/app"


export default defineComponent({
    name: 'UserList',
    components: { UserProfileModal },

	created() {
		loadData()
	},

	computed: {
		userData() {
			let result = userState.data

			if (appState.searchTags.length > 0) {
				result = result.filter((item) => item.roles && item.roles.some(tag => appState.searchTags.includes(tag)))
			}

			const searchTerm = appState.search || appState.persistentSearch

			if (searchTerm) {
				result = result.filter((item) =>
					(item.name?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
					|| (item.surname?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
					|| (item.email?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
					|| (item.position?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
				)
			}

			return result
		},

	},

    data() {
        return {
			modalItem: null,
        }
    },
	methods: {
    	closeModal() {
    		this.modalItem = null
		},
		openModal (item) {
			this.modalItem = item
			//router.push({ name: 'UserProfile', params: { id: item.id, modalItem: item }, })
		},
		mapCompanies(list) {
			if (!list) {
				return ''
			}
			// @ts-ignore
			return list.map((item) => brandState.data.find((brand) => brand.id === item)?.title || '').join(', ')
		},
		mapRoles(list) {
			if (!list) {
				return ''
			}
			// @ts-ignore
			return list.map((item) => roleState.data.find((role) => role.id === item)).filter((item) => item)
		}
	}
})
</script>
