<template>
    <div class="user-bar user-bar--mobile">
        <div class="dropdown">
            <button @click="showDropDown">
                <span class="dropdown__avatar">
                    <img :src="user.avatar" :alt="user.name">
                </span>
				<span class="dropdown__heading">
                    <strong>{{ user.name }}</strong>
                    <span>{{ user.company }}</span>
                </span>
				<span class="burger">
					<span></span>
					<span></span>
					<span></span>
				</span>
            </button>
            <transition name="fade">
                <div class="dropdown__menu" v-if="show">
                    <ul>
                        <li>
							<router-link to="/user/profile">
								<i class="icon icon--user-bar icon--myacc"></i>
								<span>{{$t('user.my_account')}}</span>
							</router-link>
						</li>
                        <li>
							<router-link to="/">
								<i class="icon icon--user-bar icon--logout"></i>
								<span>{{$t('user.logout')}}</span>
							</router-link>
						</li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UserBarMobile',
    data () {
        return {
            show: false,
            user: {
                name: 'Vojtěch Černý',
                company: 'Hame s.r.o',
                avatar: 'https://media.istockphoto.com/vectors/call-center-operators-man-avatar-icons-vector-id539973076?k=6&m=539973076&s=170667a&w=0&h=4c6zH2qFF3AjkkztyCGlTA5-v-75yzvwEtAmnK0weZU='
            }
        }
    },
	methods: {
		showDropDown () {
			this.show = !this.show
		},
		hideDropDown () {
			this.show = false
		}
	}
}
</script>
