import BackendAPI from "@/api/BackendAPI"
import {state} from "./store"
import {addTagItem, pushTagData} from "@/store/tags/mutations";
import { $t } from '@/config'
import {useToast} from "vue-toastification";

const toast = useToast()

export async function loadTagData() {
	try {
		const response = await BackendAPI.get('/tag/find')

		if (response.status !== 200) {
			console.error(response)
			toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
			return false
		}

		pushTagData(response.data.data)
	} catch (e) {

	}
}


export async function saveTagData(data) {
	if (data.id) {
		const response = await BackendAPI.post("/tag/update/" + data.id, data)

		if (response.status !== 200) {
			toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
			return false
		}
		toast.success($t('modal.change_saved'))
	} else {
		try {
			const response = await BackendAPI.post("/tag/add", data)

			if (response.status !== 200) {
				toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
				return false
			}

			await loadTagData()
			toast.success($t('modal.change_saved'))
			return true
		} catch (error) {
			toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)

			return false
		}
	}


	await loadTagData()

	return true
}

export async function addTagData(data) {
	try {
		const response = await BackendAPI.post("/tag/add", data)

		if (response.status !== 200) {
			toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)
			return false
		}

		await loadTagData()
		toast.success($t('modal.change_saved'))
		return true
	} catch (error) {
		toast.error($t('modal.failed_to_store_change') + ` (response.data.message)`)

		return false
	}
}
