
import { defineComponent } from 'vue'

import { loadTagData, saveTagData, addTagData, state as tagState } from "@/store/tags";
import {state as brandsState} from "@/store/brands"
import {state as rolesState} from "@/store/roles"
import {$t} from "@/config"
import {ProductData, ProductFileType, TagData, UserData} from "@/types";

import TagSelect from '@/components/Select/TagSelect.vue'

import DefaultInput from "@/components/Input/DefaultInput.vue";
import ChecboxtInput from "@/components/Input/CheckboxInput.vue";
import router from "@/router";

export default defineComponent({
	name: 'TagAdd',
	components: {DefaultInput, ChecboxtInput, TagSelect },
	props: {
		id: {
			type: Number,
			required: true
		}
	},

	computed: {
		availableBrands() { return brandsState.data },
		availableRoles() { return rolesState.data },
	},

	data(): {
		data: TagData,
		saving: boolean
	} {
		return {
			data: {
				id: null,
				value: null,
				name: "",
				name_cs: "",
				name_en: "",
				label: "",
				global: false,
				brands: [],
			},
			saving: false,
		}
	},

	async mounted() {
		if (this.id > 0) {
			if (tagState.data.length === 0) {
				await loadTagData()
			}

			this.data = Object.assign(this.data, tagState.data.find((item) => item.id === this.id))
		} else {
			this.data = Object.assign(this.data, {
				id: null,
				value: null,
				name: "",
				label: "",
				global: false,
				brands: [],
			})
		}

	},

	methods: {
		$t,
		discardChanges() {
			if (this.id > 0) {
				Object.assign(this.data, {
					id: null,
					value: null,
					name: "",
					label: "",
					global: false,
					brands: [],
				})
			} else {
				Object.assign(this.data, this.data = Object.assign(this.data, tagState.data.find((item) => item.id === this.id)))
			}

		},
		async saveChanges() {
			this.saving = true
			const success = await saveTagData(this.data)
			if (success) {
				router.push('/tag')
			} else {
				alert('error')
			}
		},
	}

})
