<template>
    <div class="auth auth--login">
        <h1 class="auth-title">
            <img src="@/assets/svg/tools/logo.svg" alt="Orkla databanka" width="160" height="70">
            <strong>Datapool</strong>
        </h1>
        <div class="container">
            <form @submit.prevent="handleLogin" class="auth-form" action="/dashboard">
                <div class="auth-form__title">
                    <div>
                        <h2>{{$t('login.heading')}}</h2>
                    </div>
					<div>
						<button :class='{"active": isActiveLanguage("en")}' @click.prevent="setLanguage('en')"><img src="@/assets/svg/flags/en_icon@2x.jpg"></button>
						<button :class='{"active": isActiveLanguage("cs")}' @click.prevent="setLanguage('cs')"><img src="@/assets/svg/flags/cz_icon@2x.jpg"></button>
					</div>
				</div>
                <div class="auth-form__container">
                    <div class="form__item form__item--login form__item--with-icon">
                        <label for="userEmail" hidden>{{$t('login.enter_your_email')}}</label>
						<input
							id="userEmail"
							type="email"
							name="userEmail"
							:placeholder="$t('login.enter_your_email')"
							required
							v-model="form.email"
						/>
                        <i class="icon icon--email"></i>
                    </div>
                    <div class="form__item form__item--login form__item--with-icon">
                        <label for="userPassword" hidden>{{$t('login.enter_password')}}</label>
						<input
							id="userPassword"
							type="password"
							name="userPassword"
							:placeholder="$t('login.enter_password')"
							required
							v-model="form.password"
						/>
                        <i class="icon icon--password"></i>
                    </div>
                </div>
                <div class="auth-form__button flex flex-wrap justify-content--space-between align-items--center">
                    <div>
						<button v-if="!loading"
							class="btn btn--submit btn--shadow btn--radius-5 btn--red btn--width-180"
							type="submit"
							@click="handleLogin()"
						><span class="btn__text btn__text--white">{{$t('login.enter')}}</span></button>
                    </div>
                   <div>
                       <a @click.prevent="forgottenPopup = true" href="#" class="btn-simple"><span class="btn-simple__text btn-simple__text--color-gray">{{$t('login.cant_login?')}}</span></a>
                   </div>
                </div>
            </form>
            <div class="auth-credits">
                <p class="flex align-items--center justify-content--end">&copy; {{getYear()}} Orkla Foods Česko a Slovensko a.s. <span>|</span> <a href="//incube.cz" target="_blank" class="flex align-items--center">Created by inCUBE
                    <i class="icon icon--incube" title="INCUBE"></i></a>
                </p>
            </div>

			<ForgottenPasswordPopup :open="forgottenPopup" @close-modal="forgottenPopup = null"></ForgottenPasswordPopup>

        </div>
    </div>


</template>

<script>

import {login} from "@/store/auth"
import {state as appState, setLanguage} from "@/store/app"
import ForgottenPasswordPopup from "@/components/Popup/ForgottenPasswordPopup";
import {$t} from "@/config";

export default {
	name: 'Login',
	components: {ForgottenPasswordPopup},
	data: () => ({
		forgottenPopup: false,
		form: {
			email: '',
			password: '',
		},
	}),
	methods: {
		$t,
		setLanguage(lang) {
			setLanguage(lang)
		},
		isActiveLanguage(lang) {
			return appState.language===lang;
		},
		async handleLogin(event) {
			const form = {
				"email": this.form.email.toLowerCase(),
				"password": this.form.password,
			}

			this.loading = true
			const response = await login(form)
			this.loading = false
		},
		getYear(){
			return (new Date).getFullYear();
		},
	},
}
</script>
