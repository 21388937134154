<template>
<div class="select-tag">
	<label v-if="showLabel" class="typo__label">{{label}}</label>
	<Multiselect
		v-model="localValue"
		mode="tags"
		:searchable="true"
		:options="options"
		:placeholder="$t('tags.add_tags')"
		:disabled="disabled"
		:label="labelProp"
		>
		<template v-slot:noresults>
			<span class="multiselect-noresult"> {{$t('tag_select.no_results')}}</span>
		</template>
	</Multiselect>
</div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { $t } from '@/config'

export default defineComponent({
	name: 'TagSelect',
	emits: ['update:modelValue'],

	computed: {
		localValue: {
			get(): any { return this.modelValue; },
			set(value: any) { return this.$emit('update:modelValue', value); }
		},
	},

	props: {
		showLabel: {
			default: true
		},
		label: {
			default: $t('tag_select.assign_tags')
		},
		modelValue: {
			required: true,
		},
		options: {
			required: true,
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false,
		},
		labelProp: {
			required: false,
			type: String,
			default: 'label',
		},
	},
})

</script>
