import {ProductFileType} from "@/types/index";

export const availableProductTypes: ProductFileType[] = [
	{key: 'psd_master_rgb', format : "PSD", colorspace: "RGB", type: "Master RGB"},
	{key: 'psd_master_cmyk', format : "PSD", colorspace: "CMYK", type: "Master CMYK"},
	{key: 'png_master_rgb', format : "PNG", colorspace: "RGB", type: "Master RGB"},
	{key: 'png_master_cmyk', format : "PNG", colorspace: "CMYK", type: "Master CMYK"},
	{key: 'jpeg_master_rgb', format : "JPEG", colorspace: "RGB", type: "Master RGB"},
	{key: 'jpeg_master_cmyk', format : "JPEG", colorspace: "CMYK", type: "Master CMYK"},
];
