<template>
    <div class="auth auth--lostpass flex justify-content--center align--items--center">
		<div class="container">
			<div v-if="loading" id="splash">
				<div>
					<span class="spinning-loader spinning-loader--fat"></span>
				</div>
			</div>
			<form v-else-if="tokenValid" @submit.prevent="setPassword">
				<div class="flex flex-column align-items--center">
					<img src="/img/logo.81fd679d.svg" alt="Orkla databanka">
				</div>
				<div class="flex justify-content--space-between align-items--center m-2-0-0-0">
					<div>
						<h2 v-if="isSet">{{$t('login.heading_set_password')}}</h2>
						<h2 v-else>{{$t('login.heading_change_password')}}</h2>
					</div>
					<div>
						<a href="/" class="btn-rouned flex justify-content--center align-items--center" :title="$t('login.back_to_login')">
							<i class="icon icon--arrow icon--arrow-left">{{$t('login.back_to_login')}}</i>
						</a>
					</div>
				</div>

				<div class="form__item form__item--login m-2-0-0-0">
					<label for="password">{{$t('login.enter_new_password')}}</label>
					<input id="password" type="password" v-model="form.password" required>
				</div>
				<div class="form__item form__item--login m-2-0-0-0">
					<label for="password2">{{$t('login.enter_password_again')}}</label>
					<input id="password2" type="password" v-model="form.password2" required>
				</div>
				<div class="flex justify-content--center m-2-0-0-0">
					<button v-if="isFormValid" class="btn btn--submit btn--shadow btn--radius-5 btn--red btn--width-180" type="submit">
						<span class="btn__text btn__text--white">{{$t('login.set_password')}}</span>
					</button>
					<p v-else>
						{{$t('login.passwords_must_match')}}
					</p>
				</div>
			</form>
			<div v-else class="flex flex-column align-items--center m-2-0-0-0">
				<img src="/img/logo.81fd679d.svg" alt="Orkla databanka">
				<br>
				<h2>{{$t('login.reset_request_invalid')}}</h2>
				<br>
				<a href="/" class="btn btn--submit btn--shadow btn--radius-5 btn--red btn--width-180">
					<span class="btn__text btn__text--white">{{$t('login.back_to_login')}}</span>
				</a>
			</div>
		</div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import {clearAuth, login, setAuth} from "@/store/auth";
import BackendAPI from "@/api/BackendAPI";
import router from "@/router";
import {useToast} from "vue-toastification";
import {$t} from "@/config";

const toast = useToast()

export default defineComponent({
	name: 'ForgottenPassword',
	data: () => ({
		loading: true,
		tokenValid: false,
		success: false,
		form: {
			password: '',
			password2: '',
		},
	}),

	async mounted() {
		this.loading = true
		await this.validateToken()
		this.loading = false
	},

	props: {
		uid: {
			type: Number,
			required: true,
		},
		token: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: true,
		}
	},

	computed: {
		isFormValid() { return this.form.password.length > 3 && this.form.password === this.form.password2 },
		isSet() { return this.type === 'set'; }
	},

	methods: {
		$t,
		async handleLogin(event) {
			const form = {
				"email": this.form.email.toLowerCase(),
				"password": this.form.password,
			}

			this.loading = true
			const response = await login(form)
			this.loading = false
		},

		async validateToken() {
			try {
				const response = await BackendAPI.get(`/auth/reset?uid=${this.uid}&token=${this.token}`)

				if (response.status !== 200) {
					this.tokenValid = false
					return false
				}
				this.tokenValid = true
			} catch (error) {
				toast.error($t('login.reset_request_invalid'))
				return false
			}
		},
		async setPassword() {
			try {
				this.loading = true
				const response = await BackendAPI.post(`/auth/reset?uid=${this.uid}&token=${this.token}`, this.form)
				this.loading = false
				if (response.status !== 200) {
					toast.error($t('login.failed_to_change_password'))
					return false
				}
				toast.success($t('login.login_with_new_password'))
				await router.push('/login')
			} catch (error) {
				toast.error($t('login.failed_to_change_password'))
				return false
			}
		}

	},
})
</script>
