import {getter, mutation} from './store';
import {FileUpload} from "@/types";
import {getImagePreview} from "@/utils/utils";

export const uploadResetQueue = mutation('upload-reset-queue', (state) => {
	state.uploadData = []
	state.previewData = []
})

export const uploadAddFileToQueue = mutation<File>('upload-add-file-to-queue', (state, payload) => {
	state.uploadData.push(payload)
	state.previewData.push(getImagePreview(payload))
})

export const uploadAddFilesToQueue = mutation<[]>('upload-add-files-to-queue', (state, payload) => {
	for (let file of payload) {
		state.uploadData.push(file)
		state.previewData.push(getImagePreview(file))
	}
})

export const uploadProductChange = mutation<File>('upload-product-change', (state, payload) => {
	state.uploadData.push(payload)
	state.previewData.push(getImagePreview(payload))
})


export const removeFromQueue = mutation<number>('upload-remove-file-from-queue', (state, payload) => {
	state.uploadData = state.uploadData.filter(function(value, index, arr){
		return index !== payload;
	});
	state.previewData = state.previewData.filter(function(value, index, arr){
		return index !== payload;
	});
})



// export const clearAuth = mutation('clear-auth', (state, payload) => {
// 	state.user = null
// 	state.token = ''
// 	state.expireIn = 0
// })
//
// export const setAuth = mutation<any>('set-auth', (state, { token, user, expireIn, data }) => {
// 	state.user = user
// 	state.token = token
// 	state.expireIn = expireIn
// 	state.data = data
// 	BackendAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token
// })
