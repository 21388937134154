<template>
	<modal :open="open" @close="close" class="modal-mask--center">
		<perfect-scrollbar class="ps--100">
			<div class="modal modal--popup" :class="popupClass">
				<button @click="close" class="modal-close-button"><i class="icon icon--cross"> {{$t('popup.close')}}</i></button>
				<div class="modal-container">
					<slot></slot>
				</div>
			</div>
		</perfect-scrollbar>
    </modal>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';

export default {
	name: 'OverlayPopup',
	emits: ['closeModal'],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		popupClass: {
			type: String,
			default: ''
		},
	},
	components: { Modal },
	data () {
		return {
			modalOpen: false
		}
	},
	methods: {
		close() {
			this.$emit('closeModal')
			this.modalOpen = false;
		},
	}
}
</script>
