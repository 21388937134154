<template>
	<div class="form__item">
		<input :id="idCheckbox"
			type="checkbox"
			v-model.lazy="localValue"
		>
		<span></span>
		<label :for="idCheckbox">{{ checkboxLabel }}</label>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'CheckboxInput',
	emits: ['input', 'update:modelValue'],

	computed: {
		localValue: {
			get(): Boolean { return this.modelValue; },
			set(value: Boolean) { return this.$emit('update:modelValue', value); }
		}
	},

	props: {
		idCheckbox: String,
		checkboxLabel: String,
		modelValue: {
			type: Boolean,
			required: true,
		}
	},
})
</script>
