interface State {
	uploadData: File[],
	previewData: any[],
}

const state: State = {
	uploadData: [],
	previewData: [],
}

export default state
