
import { defineComponent } from 'vue';
import {state, setCurrentId, getFilteredBrands} from "@/store/brands";
import {state as authState} from "@/store/auth";
import Modal from '@/components/Modal/Modal.vue';
import {backendBaseUrl} from '@/config';
import {isAdmin} from "@/store/auth";
import router from "@/router";


export default defineComponent({
	name: 'BrandModal',
	components: { Modal },
	data () {
		return {
			modalOpen: false,
			backendBaseUrl
		}
	},
	computed: {
		brands() {
			return getFilteredBrands.value
		}
	},

	methods: {
		setId(id) {
			setCurrentId(id)
			this.close()
			if (router.currentRoute.value.path === '/dashboard' || router.currentRoute.value.path === '/user' || router.currentRoute.value.path === '/tag') {
			 	router.push('/product')
			}
		},
		open () {
			this.modalOpen = true
		},
		close () {
			this.modalOpen = false
		}
	}
})
