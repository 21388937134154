<template>
	<transition name="fade">
		<div class="context-menu" tabindex="-1" ref="right" v-if="viewMenu" @blur="closeMenu" :style="{top:top, left:left}">
			<slot></slot>
		</div>
	</transition>
</template>

<script>
export default {
    name: 'ContextMenu',
    data () {
        return {
			viewMenu: false,
			top: '0px',
			left: '0px'
        }
    },
    methods: {
		setMenu (top, left) {

            let largestHeight = window.innerHeight - this.$refs.right.offsetHeight - 25;
            let largestWidth = window.innerWidth - this.$refs.right.offsetWidth - 25;

            if (top > largestHeight) top = largestHeight;

            if (left > largestWidth) left = largestWidth;

            this.top = top + 'px';
            this.left = left + 'px';
        },
		closeMenu () {
            this.viewMenu = false;
        },
		openMenu (e) {
            this.viewMenu = true;

            this.$nextTick (function () {
                this.$refs.right.focus();

                this.setMenu(e.y, e.x)
            }.bind(this));

            e.preventDefault()
        }

    }
}

</script>
