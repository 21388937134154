<template>
    <modal :open="modalOpen" @close="close">
        <div class="modal modal--tags">
            <button class="modal-close-button" @click="close"><i class="icon icon--cross"> {{$t('modal.close')}}</i></button>
			<div class="modal-control">
                <button class="button-simple" @click="close"><i class="icon icon--arrow-back"></i> {{$t('modal.back')}}</button>
			</div>
            <div class="modal-container">
				<div class="col col--1">
					<div class="tag-block">
						<span class="tag-block__heading">TAG</span>
						<div class="form__item form__item--tag">
							<input type="text" value="Brand" disabled>
						</div>
						<span class="tag-block__count"><span>1234</span> <span>x</span></span>
					</div>
					<div class="row">
						<button class="btn-simple btn-simple--icon-left">
							<i class="icon icon--trash-gray"></i>
							<span class="btn-simple__text btn-simple__text--size-11 btn-simple__text--color-gray">{{$t('modal.delete_tag')}}</span>
						</button>
					</div>
				</div>
				<div class="col col--2">
					<div class="modal-header">
						<div class="form__item form__item--search-modal flex align-items--center">
							<label for="searchTag" hidden>{{$t('tag_select.search')}}</label>
							<input id="searchTag"
								type="text"
								name="searchTag"
								autocomplete="off"
								:placeholder="$t('tag_select.placeholder')"
							>
							<button type="submit"></button>
						</div>
					</div>
					<div class="modal-body">
						<div class="table table--list">
							<div class="table-header">
								<div class="table-header__col table-header__col--first"></div>
								<div class="table-header__col">
									<a href="#"> {{$t('tag_select.title')}} <i class="icon icon--listing icon--short"></i></a>
								</div>
								<div class="table-header__col table-header__col--center">
									<a href="#"> {{$t('tag_select.section')}} <i class="icon icon--listing icon--short"></i> </a>
								</div>
								<div class="table-header__col table-col--center"></div>
							</div>
							<perfect-scrollbar>
								<div class="table__row" v-for="(item, index) in taglist" :key="index">
									<div class="table__col table__col--img table__col--first">
										<img loading="lazy" v-if="item.images[0].url" :src="item.images[0].url" :alt="item.title" :title="item.title" width="64" height="42">
									</div>
									<div class="table__col table__col--title"><strong>{{ item.title }}</strong></div>
									<div class="table__col table__col--center">{{ item.section }}</div>
									<div class="table__col table__col--edit table__col--right">
										<button clas="btn btn--delete" v-if="item.edit == false">
											<i class="icon icon--delete">{{$t('tag_select.delete')}}</i>
										</button>
										<button clas="btn btn--add" v-else>
											<i class="icon icon--add">{{$t('tag_select.add')}}</i>
										</button>
									</div>
								</div>
							</perfect-scrollbar>
							<div class="table__control">
								<button class="btn-simple" @click="close">
									<span class="btn__text btn__text--font-size-12 btn__text--gray-l btn__text--underline">{{$t('tag_select.discard_changes')}}</span>
								</button>
								<button class="btn btn--red btn--radius-5 btn--shadow btn--p-1-2">
									<span class="btn__text btn__text--font-size-13 btn__text--white">{{$t('tag_select.save_changes')}}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import TagJson from '@/json/tag.json'
import Modal from '@/components/Modal/Modal.vue'

import { state, closeModal } from "@/store/modal"
import { ModalType } from '@/enums'
import { $t } from '@/config'

export default defineComponent({
	name: 'TagModal',
	components: { Modal },
	data () {
		return {
			taglist: TagJson
		}
	},
	computed: {
    	modalOpen() { return state.activeType === ModalType.TagDetail }
	},

	methods: {
		show () {
			// openProductModal(state.data.id)
		},
		close () {
			closeModal()
		}
	}
})
</script>
