<template>
    <div class="dash">
		<perfect-scrollbar>
			<div class="wrapper wrapper--dash">
				<h2 class="title">{{$t('dashboard.welcome_heading')}}</h2>
				<SelectBrand @open="openModal" />
				<div class="dash-grid dash-grid--1">
					<div class="grid-column">
						<ViewedFiles/>
					</div>
					<div class="grid-column">
						<Store/>
					</div>
				</div>
			</div>
			<ViewedProducts/>
		</perfect-scrollbar>
    </div>
</template>

<script>
import SelectBrand from '@/components/Dashboard/SelectBrand.vue'
import ViewedFiles from '@/components/Dashboard/ViewedFiles.vue'
import ViewedProducts from '@/components/Dashboard/ViewedProducts.vue'
import Store from '@/components/Dashboard/Store.vue'

export default {
    name: 'Dashboard',
    components: {
        ViewedFiles, Store, SelectBrand, ViewedProducts
    },
	emits: ['open'],
	methods: {
		openModal () {
			this.$emit('open')
		}
	}
}
</script>
