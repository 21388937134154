<template>
    <div class="dashboard">
        <HeaderNav
			@toggleSidebar="openSidebar"
			@open="openSearchModal"
		/>
        <div class="container flex">
            <div id="modals" class="content">
				<SidebarNav :class="{'active': open }" @open="openBrandModal"/>
                <div data-dashboard-content class="inner">
					<DashboardContent @open="openBrandModal"/>
                </div>
				 <SearchModal ref="modalSearch"/>
				<BrandModal ref="modalBrand"/>
            </div>
        </div>
    </div>

</template>

<script>
import HeaderNav from '@/views/Partials/HeaderNav.vue';
import SidebarNav from '@/views/Partials/SidebarNav.vue';
import DashboardContent from '@/views/Layout/DashboardContent.vue';

import BrandModal from '@/components/Modal/BrandModal.vue';
import SearchModal from '@/components/Modal/SearchModal.vue';

export default {
    name: 'dashboardlayout',
    components: {
        HeaderNav, SidebarNav, DashboardContent, BrandModal, SearchModal
    },
	data: () => ({
		open: false
	}),
	methods: {
		openSidebar () {
			this.open = !this.open
		},
		openSearchModal () {
			this.$refs.modalSearch.open()
		},
		openBrandModal () {
			this.$refs.modalBrand.open()
		},
		openWarningPopup () {
			this.$refs.popupWarning.show()
		},
		openSuccessPopup () {
			this.$refs.popupWarning.close()
			this.$refs.popupDeleteSuccess.show()
		}
	}
}
</script>
