<template>

	<div class="brand-grid brand-grid--select">
		<div
			v-for="item in brands" :key="item.id"
			class="form__item form__item--brand-select"
			v-bind:style="{ background: item.color }"
			@click="setId(item.id)"
		>
			<label>
				<input type="radio" name="brand">
				<img :src="backendBaseUrl + item.image" :alt="item.title">
			</label>
		</div>

		<button class="brand brand--select" @click="openModal">
			<img :src="backendBaseUrl + '/assets/brands/star.png'" :alt="$t('dashboard.other_brands')">
			<strong>{{$t('dashboard.other_brands')}}</strong>
		</button>

	</div>

</template>


<script lang="ts">
import { defineComponent } from 'vue';
import {state, setCurrentId, getFilteredBrands} from "@/store/brands";
import {backendBaseUrl} from '@/config';
import router from "@/router";

export default defineComponent({
	name: 'SelectBrand',
	data () {
		return {
			backendBaseUrl
		}
	},
	computed: {
		brands() {
			let obj = getFilteredBrands.value;
			let result = {};
			for (let k in obj) {
				if (!obj[k].color) continue;
				result[k] = obj[k];
			}
			return result;
			// return getFilteredBrands.value
		}
	},
	emits: ['open'],
	methods: {
		setId(id) {
			setCurrentId(id)
			if (router.currentRoute.value.path === '/dashboard' || router.currentRoute.value.path === '/user' || router.currentRoute.value.path === '/tag') {
			 	router.push('/product')
			}
		},
		openModal () {
			this.$emit('open')
		}
	}
})
</script>
