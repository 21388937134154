
import {defineComponent} from 'vue'
import { $t } from '@/config'
import {FileUpload, FileUploadQueue} from "@/types";
import {UploadType} from "@/enums";

import {removeFromQueue, state as uploadState, uploadResetQueue} from "@/store/upload";
import {state as brandState} from "@/store/brands";
import {state as tagState} from "@/store/tags";
import {state as productState} from "@/store/products";

import BackendAPI from "@/api/BackendAPI";
import UploadDropTarget from "@/components/Upload/UploadDropTarget.vue";
import {setErrorMessage} from "@/store/app";
import SuccessPopup from "@/components/Popup/SuccessPopup.vue";


export default defineComponent({
	name: 'ProductsUpload',
	components: {SuccessPopup, UploadDropTarget },
	emits: ['updatedQueue'],

	data(): {
		fileQueue: FileUpload[],
		uploading: boolean,
		successPopup: boolean,
		groupBrand: number,
		groupTags: [],
		groupCategory: UploadType,
		previews: [],
	} {
		return {
			fileQueue: [],
			uploading: false,
			successPopup: false,
			groupBrand: brandState.currentId,
			groupTags: [],
			groupCategory: UploadType.PRODUCT,
			previews: [],
		}
	},

	mounted() {
		this.updateQueueLength(this.uploadQueueLength)
	},

	computed: {
		uploadQueueLength() { return uploadState.uploadData.length },
		uploadQueue() { return uploadState.uploadData },
		previewQueue() { return uploadState.previewData },
		brandSelectOptions() { return brandState.data },
		tagSelectDatasource() {
			// @ts-ignore
			const groupBrand = this.groupBrand || brandState.currentId
			return tagState.data.filter(
				(tag) => tag.global === true || tag.brands && tag.brands.includes(groupBrand)
			)
		},
		productSelectDatasource() {
			//this.fileQueue.forEach((item) => {item.})
			// @ts-ignore
			const groupBrand = this.groupBrand || brandState.currentId

			return productState.data.filter(
				(product) => product.brand_id === groupBrand
			)
		},
		uploadTypeSelectDatasource() {
			return Object.keys(UploadType).map((value) => { return {value: UploadType[value], 'label': $t('type.' + UploadType[value])}})
		},
		isProductUpload(): boolean {
			return this.groupCategory === UploadType.PRODUCT ||
				this.groupCategory === UploadType.PRODUCT_SK ||
				this.groupCategory === UploadType.PRODUCT_HU
		}
	},

	watch: {
		uploadQueueLength(newValue) { this.updateQueueLength(newValue) },
	},

	methods: {
		updateQueueLength(length) {
			while (this.fileQueue.length < length) {
				this.fileQueue.push({
					name: "",
					description: "",
					tags: [],
					ean: '',
					sap_id: '',
					ids: [],
				});
			}
		},

		removeFromQueue(idx) {
			removeFromQueue(idx)
			this.fileQueue = this.fileQueue.filter((value, index, arr) => index !== idx)
		},

		async upload() {
			this.uploading = true
			let data: FileUploadQueue = {
				type: this.groupCategory,
				file: [],
				brandId: this.groupBrand,
				tags: this.groupTags,
			}
			let formData = new FormData()
			this.uploadQueue.forEach((f,x) => {
				formData.append(`file[${x}]`, f)
				data.file[x] = this.fileQueue[x]
			});
			formData.append('data', JSON.stringify(data))

			try {
				const uploadResult = await BackendAPI.post('upload/upload', formData, {
					'headers': {
						'Accept': 'application/json',
						'Content-Type': 'multipart/form-data',
					},
				})

				this.uploading = false

				if (uploadResult.status !== 200) {
					setErrorMessage('Upload failed')
					throw new Error('Upload failed');
				}

				uploadResetQueue();
				this.fileQueue = [];

				this.successPopup = true

				//pushProductData(uploadResult.data.data)

			} catch (e) {
				console.log(e)
			}
		}

	}
})
