<template>
    <modal :open="open" @close="close">
        <div class="modal modal--user">
            <button class="modal-close-button" @click="close"><i class="icon icon--cross"> {{$t('modal.close')}}</i></button>
			<perfect-scrollbar>
                <div class="modal-control">
                    <button class="button-simple" @click="close"><i class="icon icon--arrow-back"></i> {{$t('modal.back')}}</button>
                </div>
                <div class="modal-container">
                    <div class="modal-header">
                        <div class="img img--avatar">
                            <img v-if="modalItem.avatar" :src="modalItem.avatar" :alt="modalItem.name">
                        </div>
                        <h2>{{ modalItem.name }} {{ modalItem.surname }}</h2>
                        <div v-if="modalItem.company_img" class="img img--company">
                            <img :src="modalItem.company_img" :alt="modalItem.company">
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="modal-body__col">
                            <div>
                                <strong>{{$t('user.company')}}</strong>
                                <span>{{ modalItem.company_name }}</span>
                            </div>
                            <div>
                                <strong>{{$t('user.phone')}}</strong>
                                <span>{{ modalItem.phone }}</span>
                            </div>
                            <div>
                                <strong>{{$t('user.last_login')}}</strong>
                                <span>{{ modalItem.last_login_formatted }}</span>
                            </div>
                        </div>
                        <div class="modal-body__col">
                            <div>
                                <strong>{{$t('user.position')}}</strong>
                                <span>{{ modalItem.position }}</span>
                            </div>
                            <div>
                                <strong>{{$t('user.email')}}</strong>
                                <span>{{ modalItem.email }}</span>
                            </div>
                            <div>
                                <strong>{{$t('user.registration_date')}}</strong>
                                <span>{{ modalItem.created_at_formatted }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <router-link :to="{ name: 'UserProfile', params: { id: modalItem.id, modalItem }, }" class="btn-simple btn-simple--icon-left">
                            <i class="icon icon--pen-gray"></i>
                            <span class="btn-simple__text btn-simple__text--size-11 btn-simple__text--color-gray">{{$t('user.edit_details')}}</span>
                        </router-link>
                    </div>
                </div>
            </perfect-scrollbar>
		</div>
    </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Modal from '@/components/Modal/Modal.vue';
import {
	state as modalState,
	openProductModal, closeModal,
} from '@/store/modal'

export default defineComponent({
	name: 'profileModal',
	components: { Modal },
	emits: ['close'],
	props: [
		'modalItem',
		'show',
		'open',
	],
	data () {
		return {
		}
	},

	computed: {
		modalOpen(): boolean {
			return this.open
			//return modalState.open
		},
	},

	methods: {
		close() {
			this.$emit('close')
		}
		// show () {
		// 	this.modalOpen = true;
		// },
		// close () {
		// 	this.modalOpen = false;
		// }
	}
})
</script>
