import jwt from "jsonwebtoken"
import BackendAPI from "@/api/BackendAPI"
import { setAuth, clearAuth } from "@/store/auth"
import moment from "moment/moment"
import router from "@/router"
import {preload} from "@/store/app";
import {useToast} from "vue-toastification";
import {$t} from '@/config';
const toast = useToast()

export async function login(form) {
	try {
		const response = await BackendAPI.post('/auth/login', form)

		if (response.status !== 200) {
			localStorage.removeItem('jwt-token')
			delete BackendAPI.defaults.headers.common['Authorization']
			clearAuth()
			console.error(response)
			toast.error($t('auth.login_failed'))

			return false
		}

		localStorage.setItem('jwt-token', response.data.token)
		const token = jwt.decode(response.data.token)
		const expireIn = token.exp - moment().unix()


		setAuth({
			token: response.data.token,
			expireIn: expireIn,
			user: token.user,
			data: token.data
		})

		await router.push('/dashboard')
		await preload()
		return true
	} catch (error) {
		toast.error($t('auth.login_failed'))
		return false
	}
}

export function logout() {
	localStorage.removeItem('jwt-token')
	delete BackendAPI.defaults.headers.common['Authorization']
	clearAuth()
	router.push('/login')
}
