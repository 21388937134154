import { TagData } from "@/types";

interface State {
	data: TagData[]
}

const state: State = {
	data: []
}

export default state
