
import { defineComponent } from 'vue'

import TagJson from '@/json/tag.json'
import Modal from '@/components/Modal/Modal.vue'

import { state, closeModal } from "@/store/modal"
import { ModalType } from '@/enums'
import { $t } from '@/config'

export default defineComponent({
	name: 'TagModal',
	components: { Modal },
	data () {
		return {
			taglist: TagJson
		}
	},
	computed: {
    	modalOpen() { return state.activeType === ModalType.TagDetail }
	},

	methods: {
		show () {
			// openProductModal(state.data.id)
		},
		close () {
			closeModal()
		}
	}
})
